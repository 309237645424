export const LANGUAGE = ["ar", "en"] as const;

export const GOOGLE_CLIENT_ID =
  "469378053586-2n1jnabu35n9pru6sva9leoens5dgv9b.apps.googleusercontent.com" as const;

export const FACEBOOK_CLIENT_ID = 1142463836975089 as const;

export const SUBSCRIPTION_AMOUNT = 300 as const;
export const DISCOUNTED_AMOUNT = 0 as const;

export const PAYMENT_CLIENT_ID = 814125262505 as const;

export const GPT_MODEL = "gpt-4o-mini" as const;
