import PrivacyPolicyIcon from "@/assets/images/privacy-policy.svg?react";
import { Link } from "react-router-dom";

import classes from "@/Components/UserPrivacy/PrivacyPageContent.module.css";

export default function PrivacyPageEnContent() {
  return (
    <section className={`container-80 ${classes.privacySection}`}>
      <h1 className={classes.privacyHeader}>
        <PrivacyPolicyIcon />
        سياسة الخصوصية
      </h1>
      <div className={classes.privacyContainer}>
        <h2>ما الذي تغطيه سياسة الخصوصية هذه؟</h2>
        <p>
          يُعَد CodeBites منتجًا تقدِّمه شركة نجوى المحدودة (المُشار إليها فيما بعدُ باسم نجوى).
          توضِّح سياسة الخصوصية هذه كيفية قيام CodeBites، نيابةً عن نجوى، بجمع واستخدام المعلومات
          الشخصية فيما يتعلَّق بالمنتجات والخدمات المرتبطة بهذه السياسة. يُشار إلى هذه المنتجات
          والخدمات باسم «خدماتنا» في سياسة الخصوصية هذه. سنستخدم كلمة CodeBites للإشارة إلى هذا
          المنتج التابع لشركة نجوى في هذه السياسة.
        </p>
        <p>يمكن للأفراد شراء خدماتنا (يُشار إليهم مجتمعين باسم «الأفراد» في هذه السياسة).</p>
        <p>
          في هذه السياسة، يُعرَّف «المُستخدم» بأنه أي شخص يَستخدم خدمات CodeBites. ومع كل مستخدم، قد
          يتعيَّن علينا جمع «بيانات المستخدم» (المُعرَّفة هنا بأنها أي معلومات أو بيانات شخصية
          تتعلَّق بأي مستخدم، يتم جمعها بواسطة نجوى أو نيابةً عنها) من أجل تقديم خدماتنا.
        </p>
        <p>
          تغطي هذه السياسة كيفية تعامل CodeBites مع بيانات المستخدم بصفته الجهة المتحكِّمة في
          البيانات عند تقديم الخدمات للأفراد.
        </p>
        <h2>التزامات CodeBites</h2>
        <p>
          نحن ملتزمون بحماية حقوق المستخدمين، كما هو محدَّد بموجب اللائحة العامة لحماية البيانات
          (GDPR) في الاتحاد الأوروبي، ومن خلال اتباع مبادئ الخصوصية الآتية:
        </p>
        <ul>
          <li>إنشاء بيئة تعلُّم رقمية آمِنة لك.</li>
          <li>
            عدم مشاركة معلوماتك الشخصية ولا بيعها لأطراف ثالثة غير مشارِكة مشارَكة مباشرة في تقديم
            خدمات نجوى (ما لم يُطلَب منَّا ذلك بموجب القانون).
          </li>
          <li>تزويدك بإمكانية الوصول إلى المعلومات التي تقدِّمها لنا والتحكُّم فيها.</li>
          <li>عدم عرض أي إعلانات خارجية على خدمات CodeBites.</li>
        </ul>
        <p>
          نعتبر جميع بيانات المستخدم التي نجمعها سرية تمامًا. وبشكل عام، لا نستخدم هذه البيانات لأي
          غرض آخر غير تحسين خدماتنا وتقديمها للمستخدم. يخضع جمع بيانات المستخدم واستخدامها ومشاركتها
          لتحكُّم الفرد الذي اشترى خدماتنا.
        </p>
        <h2>ما بيانات المستخدم التي يجمعها CodeBites ويخزِّنها؟</h2>
        <p>
          من أجل تقديم خدماتنا بفعالية، هناك عدد من المعلومات (بيانات المستخدم) التي يحتاج CodeBites
          إلى جمعها وتخزينها.
        </p>
        <p>
          إذا اشتركت في خدماتنا، فإنك توافق على قيام CodeBites بتخزين ومعالجة المعلومات الآتية عنك:
        </p>
        <ul>
          <li>الاسم الأول والاسم الأخير.</li>
          <li>عنوان البريد الإلكتروني.</li>
          <li>بلد الإقامة.</li>
          <li>استخدام خدمات CodeBites، مثل الصفحات التي تزورها والإجراءات التي تتخذها.</li>
        </ul>
        <p>
          لمزيد من المعلومات حول البيانات التي ستُجمَع بواسطة خدمات CodeBites المحدَّدة، يُرجى
          التواصل معنا عبر <Link to='mailto:nagwa@nagwa.com'>nagwa@nagwa.com</Link>.
        </p>
        <h2>كيف تَجمع خدمات CodeBites بيانات المستخدم؟</h2>
        <p>إننا نجمع المعلومات التي تمثِّل بيانات المستخدم الخاصة بك عندما:</p>
        <ul>
          <li>تشارك معلوماتك معنا من خلال التسجيل لدينا، أو استخدام خدماتنا.</li>
          <li>تتواصل معنا عبر طُرق إلكترونية أو غير إلكترونية.</li>
          <li>
            تفتح رسائل البريد الإلكتروني التي نرسلها إليك، أو تتفاعل عبر موقعنا الإلكتروني. تُجمَع
            هذه المعلومات من خلال ملفات تعريف الارتباط. يُرجى قراءة سياسة ملفات تعريف الارتباط
            الخاصة بنا لمزيد من المعلومات.
          </li>
        </ul>
        <p>
          نستخدم إحصاءات Google لجمع المعلومات ومتابعتها وتحليلها. لدى Google سياسة خصوصية خاصة بها،
          ويمكن الاطلاع عليها من خلال الرابط{" "}
          <Link to='https://policies.google.com/privacy'>https://policies.google.com/privacy.</Link>
        </p>
        <p>
          نستخدم Adjust؛ وهو برنامج تحليلات وتسويق خاص بتطبيقات الهاتف المحمول. ويمكن الاطلاع على
          سياسة الخصوصية الخاصة به من خلال الرابط{" "}
          <Link to='https://www.adjust.com/terms/privacy-policy/'>
            https://www.adjust.com/terms/privacy-policy/.
          </Link>
        </p>
        <p>
          نستخدم أيضًا خدمات META (مثل الإعلان وتسجيل الدخول عبر وسائل التواصل الاجتماعي). ويمكن
          الاطلاع على سياسة الخصوصية الخاصة به من خلال الرابط{" "}
          <Link to='https://web.facebook.com/about/privacy/'>
            https://web.facebook.com/about/privacy/.
          </Link>
        </p>
        <h2>مع مَن سيشارك CodeBites بياناتي؟</h2>
        <p>
          لا نبيع أيًّا من بيانات المستخدم. كما أننا لا نشارك أيًّا من بيانات المستخدم مع أي أطراف
          ثالثة غير مشارِكة مشارَكة مباشرة في تقديم خدمات CodeBites، إلا إذا كان ذلك مطلوبًا بموجب
          القانون.
        </p>
        <p>
          قد يشارك CodeBites بيانات المستخدم إذا كان لديه يقين جازم بأن الوصول إلى هذه المعلومات أو
          استخدامها أو الحفاظ عليها أو الكشف عنها ضروري إلى حدٍّ ما من أجل:
        </p>
        <ul>
          <li>الامتثال لأي قانون أو لائحة أو إجراء قانوني معمول به، أو طلب حكومي قابل للتنفيذ.</li>
          <li>تطبيق شروط الخدمة المعمول بها، ويشمل ذلك التحقيق في الانتهاكات المحتملة للبيانات.</li>
          <li>اكتشاف أو منع أو حل المشكلات المتعلِّقة بالاحتيال أو المشكلات الأمنية أو الفنية.</li>
          <li>
            الحماية من أي ضرر وشيك لحقوق أو ممتلكات أو سلامة نجوى وCodeBites أو مستخدميه أو الجمهور،
            كما هو مطلوب أو مسموح به بموجب القانون.
          </li>
        </ul>
        <p>
          قد نُضطر أيضًا إلى مشاركة بيانات المستخدم إذا دخلت نجوى في عملية دمج أو استحواذ أو أي شكل
          من أشكال بيع بعض أو كل أصولها. الأساس القانوني لمثل هذا النقل هو مصالحنا المشروعة. سيتم
          إخطارك بنقل البيانات المقترح في الوقت المناسب، وسيُتاح لك خيار حذف حسابك. في حالة حدوث
          ذلك، سنرسل لك إشعارًا قبل نقل المعلومات الشخصية وإخضاعها لسياسة خصوصية مختلفة.
        </p>
        <p>
          نستخدم بيانات مجمَّعة مجهولة المصدر لتحليل كيفية تفاعل مستخدمينا مع خدماتنا من أجل تحسينها
          وتقديم خدمات جديدة. عندما نفعل ذلك، نضمن إزالة جميع البيانات التعريفية المباشرة لأي شخص؛
          وهو ما يعني أن البيانات لم تَعُد مخصَّصة لأغراض لوائح خصوصية البيانات.
        </p>
        <h2>ما القوانين واللوائح التي يتبعها CodeBites عند التعامل مع بياناتي؟</h2>
        <p>
          عند شرائك حق الوصول إلى خدمات CodeBites أو عند الاتصال بك لأغراض تسويقية، فإننا نعمل
          بوصفنا جهة تحكُّم في البيانات. وبموجب اللائحة العامة لـ GDPR، يتعيَّن علينا إبلاغك بالأساس
          القانوني للتعامل مع بيانات المستخدم الخاصة بك. يجوز لنا استخدام بيانات المستخدم في الحالات
          الآتية:
        </p>
        <ul>
          <li>إبرام عقد معك (عند شرائك لخدمات CodeBites على سبيل المثال).</li>
          <li>
            الامتثال لالتزاماتنا القانونية (على سبيل المثال الالتزام بالإبلاغ عن المعلومات الشخصية
            أو مشاركتها أو أرشفتها بطريقة معينة).
          </li>
          <li>
            استخدامها لأي أغراض تجارية تندرج ضمن مصالحنا المشروعة بوصفنا مزوِّدًا تجاريًّا للخدمات
            التعليمية.
          </li>
          <li>
            حصولنا على موافقتك (مثلًا عندما نطلب منك تحديدًا الموافقة على استخدام بيانات المستخدم
            الخاصة بك استخدامًا معينًا).
          </li>
        </ul>
        <h2>هل سيرسِل لي CodeBites رسائل تسويقية؟ وكيف يمكنني إلغاء الاشتراك بها؟</h2>
        <p>
          قد نستخدم بيانات الاتصال التي يقدِّمها المستخدمون للاشتراك في خدماتنا لإعلام المستخدمين
          بالعروض المتاحة على خدمات أخرى يقدِّمها CodeBites، وذلك ما لم يختر المستخدم إيقاف تلقِّي
          هذه الرسائل.
        </p>
        <p>
          لن تُرسَل أي رسائل تسويقية إلى أي مستخدم قام باختيار إيقاف تلقِّي هذه الرسائل. يمكن
          للمستخدمين إلغاء تلقِّي الرسائل التسويقية في أي وقت. تحتوي جميع الرسائل التسويقية المرسَلة
          من CodeBites على رابط «إلغاء الاشتراك» في نهاية الرسالة.
        </p>
        <h2>كيف يمكنني حماية بياناتي؟</h2>
        <p>
          تأمين بيانات المستخدمين أمر بالغ الأهمية بالنسبة إلينا. إننا نتخذ تدابير أمنية مناسبة
          للحماية من الوصول غير المصرَّح به، وللتحقُّق من هوية المستخدم قبل منحه حق الوصول إلى حسابه
          أو تصحيح معلوماته.
        </p>
        <p>
          ننصحك بإنشاء كلمة مرور قوية دائمًا للمساعدة في ضمان أمان حسابك، وننصحك أيضًا بعدم مشاركتها
          أبدًا مع الآخرين. كما نحثُّك على ألَّا تَستخدم خدماتنا إلا عبر شبكات آمِنة، وأن تهتم
          بتحديث البرامج الخاصة بأمان الإنترنت والحماية من الفيروسات على الأجهزة التي تستخدمها
          للوصول إلى خدماتنا. يُرجى تغيير كلمة المرور على الفور إذا كنت تشك في حدوث اختراق للبيانات،
          أو إذا كانت لديك مخاوف متعلِّقة بأمان بياناتك، وأن تتواصل معنا من خلال البريد الإلكتروني{" "}
          <Link to='mailto:nagwa@nagwa.com'>nagwa@nagwa.com</Link>.
        </p>
        <p>
          جمع بيانات المستخدم جمعًا غير صحيح أو إساءة استخدامها يُعتبر انتهاكًا لسياسة الخصوصية هذه.
          في حالة حدوث ذلك، يُرجى إبلاغنا من خلال البريد الإلكتروني{" "}
          <Link to='mailto:nagwa@nagwa.com'>nagwa@nagwa.com</Link>.
        </p>
        <p>
          إذا علمنا أن هناك مَن يخالف سياسة الخصوصية هذه أو الشروط والأحكام الخاصة بنا، يحق لنا
          إنهاء وصول هذا الشخص أو المؤسسة إلى خدماتنا على الفور، ودون إشعار سابق.
        </p>
        <h2>ما المدة التي سيَحتفظ خلالها CodeBites ببياناتي؟</h2>
        <p>
          سنحتفظ ببيانات المستخدم الخاصة بك ما دمتَ عميلًا لدى CodeBites، ولأي مدة بعد ذلك تكون فيها
          بياناتك مطلوبة للمعالجة من أجل الاستمرار في توفير خدماتنا، ما لم يَقتضِ القانون غير ذلك أو
          يَسمح بفترة أطول.
        </p>
        <h2>هل تُرسَل بياناتي إلى بلد آخر؟</h2>
        <p>
          إننا نقدِّم خدماتنا على نطاق دولي. وفقًا لمتطلَّبات GDPR، يتم إجراء عمليات تدقيق على جميع
          مقدِّمي الخدمات للتأكُّد من امتثالهم لمتطلَّبات GDPR، واتباعهم فعليًّا لسياسة خصوصية
          CodeBites.
        </p>
        <p>
          لتقديم الخدمة، فإننا نستخدم عددًا من معالِجات البيانات من جهات أخرى مُلزَمة تعاقديًّا
          بالامتثال لسياسة الخصوصية الموضَّحة في هذه الوثيقة.
        </p>
        <p>
          قد تُنقَل معلوماتك، التي تشمل بيانات المستخدم الشخصية، ويُحتفَظ بها على أجهزة كمبيوتر
          موجودة خارج دولتك أو مقاطعتك أو غيرها من الولايات القضائية الحكومية التي قد تختلف فيها
          قوانين حماية البيانات عن تلك القوانين الخاصة بالمنطقة التي تُوجَد أنت بها. يشمل ذلك
          المناطق خارج الاتحاد الأوروبي. لضمان الامتثال لمتطلَّبات GDPR، فإن لدى CodeBites بنودًا
          تعاقدية قياسية تكون سارية عند الضرورة. على سبيل المثال، الشركات في الولايات المتحدة
          الأمريكية مسجلة في برنامج حماية الخصوصية Privacy Shield.
        </p>
        <p>
          موافقتك على سياسة الخصوصية هذه، وكذلك موافقتك على تقديم هذه المعلومات، تمثِّلان موافقةً
          على نقل المعلومات.
        </p>
        <p>
          في حالة وجود نزاع يتعلَّق بنقل البيانات دوليًّا، فإنك توافق على أن تكون محاكم إنجلترا
          وويلز هي الولاية القضائية الحصرية للقضية.
        </p>
        <h2>ماذا لو كان عُمري أقلَّ من 18 عامًا؟</h2>
        <p>
          إننا نقدِّم خدمات قد تَستهدف مستخدمين أعمارهم أقلُّ من 18 عامًا. ونحرص دائمًا على طلب
          المعلومات اللازمة فقط لتقديم خدماتنا لهؤلاء المستخدمين.
        </p>
        <p>
          إذا كان عُمرك أقلَّ من 18 عامًا ولديك أي أسئلة حول بيانات المستخدم الخاصة بك، وكيفية
          استخدامها من خلال خدماتنا، فإننا نُوصيك بالتواصل مع والديك، أو الوَصِي القانوني، أو
          المؤسسة التي تم من خلالها إعداد وصولك إلى خدماتنا.
        </p>
        <h2>ما حقوقي فيما يتعلَّق ببيانات المستخدم الخاصة بي؟</h2>
        <p>
          قد تكون لديك حقوق في مناطق معينة، مثل المنطقة الاقتصادية الأوروبية، متعلِّقة ببيانات
          المستخدم الخاصة بك. يشمل ذلك الحق في تحديث معلوماتك الشخصية وتصحيحها، والحصول على نسخة من
          بياناتك عند الطلب، وحذف بياناتك إذا لم يَعُد من الضروري لنا الاحتفاظ بها لتحقيق الأغراض
          التي قمنا من أجلها بجمع هذه البيانات. يمكنك أيضًا طلب تقييد معالجة بياناتك، وفي هذه الحالة
          قد لا نتمكن من تقديم خدماتنا لك.
        </p>
        <p>
          إذا قمت بشراء حق الوصول إلى خدمات CodeBites مباشرةً لأنك عميل فردي، فإنه يمكنك إذن التواصل
          معنا بخصوص طلبك باستخدام تفاصيل الاتصال الواردة في نهاية سياسة الخصوصية هذه. سنتعامل مع أي
          طلب، ونتخذ إجراءات بشأنه، بما يتوافق مع قوانين حماية البيانات المعمول بها.
        </p>
        <p>
          إذا تم شراء حق الوصول إلى خدمات CodeBites من قِبَلك أو من قِبَل أحد الوالدين أو الوَصِي،
          وكنت غير راضٍ عن ممارسات الخصوصية لدينا، فإنه يحق لك عندئذٍ تقديم شكوى إلى مفوض مكتب
          المعلومات (إذا كنت مقيمًا في المملكة المتحدة)، أو إلى الجهة المسئولة عن الإشراف على حماية
          البيانات في بلدك.
        </p>
        <h2>هل سنقوم بإجراء تغييرات على سياسة الخصوصية هذه؟</h2>
        <p>
          قد نُجري تغييرات على سياسة الخصوصية هذه من وقتٍ إلى آخر. سنعرض دائمًا تاريخ آخِر تحديث
          بشكلٍ واضح.
        </p>
        <h2>كيف يمكنني التواصل مع CodeBites؟</h2>
        <p>
          إذا كانت لديك أي استفسارات حول سياسة الخصوصية لدينا، يُرجى التواصل معنا من خلال البيانات
          الآتية:
        </p>
        <address>
          <p>شركة نجوى المحدودة</p>
          <p>يورك هاوس</p>
          <p>41 شارع «شيت»</p>
          <p>ويندسور</p>
          <p>SL4 1DD</p>
          <p>
            الهاتف: <Link to='tel:+4401753832522'>+44 (0)1753 832 522</Link>
          </p>
          <p>
            البريد الإلكتروني: <Link to='mailto:nagwa@nagwa.com'>nagwa@nagwa.com</Link>
          </p>
        </address>
      </div>
    </section>
  );
}
