import Banner from "@/assets/images/banner-illustration.svg?react";
import CheckMarkIcon from "@/assets/images/check.svg?react";
import useUserActionsStore from "@/store/UserActionsStore";
import type { CourseData, Language } from "@/types";
import { useTranslation } from "react-i18next";
import LinkBtn from "../LinkBtn/LinkBtn";

import useUserStore from "@/store/UserStore";
import { formatDateStr } from "@/utils/Utils";
import classes from "./HeroSection.module.css";

export default function HeroSection({ firstCourse }: { firstCourse: CourseData | undefined }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { user } = useUserStore(state => state);

  const { setRedirectPath } = useUserActionsStore(state => state);

  return (
    <section className={classes.heroSection}>
      <div className='container-125'>
        <div>
          <h1 className={classes.heroTitle}>
            <span>{t("home_header_part1_message")}</span>
            <span>{t("home_header_part2_message")}</span>
          </h1>
          <p className={classes.heroText}>
            {t("home_welcome_part1_message")} <strong>{t("home_welcome_part2_message")}</strong>
          </p>
          {!user?.subscriptionDetails ? (
            <div className={classes.heroCtaContainer}>
              <LinkBtn
                variant='signUp'
                to={`/${language}/courses/${firstCourse?.course_id}/chapters/${firstCourse?.chapters[0]?.chapter_id}/`}
                className={classes.heroCta}
              >
                {t("try_for_free_button")}
              </LinkBtn>
              <LinkBtn
                variant='payment'
                to={`/${language}/payment/`}
                className={classes.heroCta}
                onClick={setRedirectPath.bind(null, `/${language}/payment`)}
              >
                {t("subscribe_now_button")}
              </LinkBtn>
            </div>
          ) : (
            <div className={classes.subscribedBanner}>
              <p>
                {t("subscribed_label")}
                <CheckMarkIcon />
              </p>
              <p>
                {t("subscribe_due_date_label", {
                  subscription_due_date: formatDateStr(
                    user.subscriptionDetails.end_date,
                    language as Language
                  ),
                })}
              </p>
            </div>
          )}
        </div>
        <div className={classes.heroImgWrapper}>
          <Banner />
        </div>
      </div>
    </section>
  );
}
