import {
  ChangeEvent,
  ClipboardEvent,
  CSSProperties,
  FocusEvent,
  Fragment,
  InputHTMLAttributes,
  KeyboardEvent,
  ReactNode,
  RefCallback,
  useEffect,
  useRef,
  useState,
} from "react";

type AllowedInputTypes = "password" | "text" | "number" | "tel";

type InputProps = Required<
  Pick<
    InputHTMLAttributes<HTMLInputElement>,
    | "value"
    | "onChange"
    | "onFocus"
    | "onBlur"
    | "onKeyDown"
    | "onPaste"
    | "aria-label"
    | "autoComplete"
    | "style"
    | "inputMode"
    | "onInput"
  > & {
    ref: RefCallback<HTMLInputElement>;
    placeholder: string | undefined;
    className: string | undefined;
    type: AllowedInputTypes;
    disabled: boolean;
  }
>;

interface OTPInputProps {
  /** Value of the OTP input */
  value?: string;
  /** Number of OTP inputs to be rendered */
  numInputs?: number;
  /** Callback to be called when the OTP value changes */
  onChange: (otp: string) => void;
  /** Callback to be called when pasting content into the component */
  onPaste?: (event: ClipboardEvent<HTMLDivElement>) => void;
  /** Function to render the input */
  renderInput: (inputProps: InputProps, index: number) => ReactNode;
  /** Whether the first input should be auto focused */
  shouldAutoFocus?: boolean;
  /** Placeholder for the inputs */
  placeholder?: string;
  /** Function to render the separator */
  renderSeparator?: ((index: number) => ReactNode) | ReactNode;
  /** Style for the container */
  containerStyle?: CSSProperties | string;
  /** Style for the input */
  inputStyle?: CSSProperties | string;
  /** The type that will be passed to the input being rendered */
  inputType?: AllowedInputTypes;
  /** Do not apply the default styles to the inputs, will be removed in future versions */
  skipDefaultStyles?: boolean; // TODO: Remove in next major release
  /** Disable all the inputs */
  disabled?: boolean;
}

const isStyleObject = (obj: unknown) => typeof obj === "object" && obj !== null;

const OTPInput = ({
  value = "",
  numInputs = 4,
  onChange,
  onPaste,
  renderInput,
  shouldAutoFocus = false,
  inputType = "text",
  renderSeparator,
  placeholder,
  containerStyle,
  inputStyle,
  skipDefaultStyles = false,
  disabled = false,
}: OTPInputProps) => {
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  // Save Otp value in a ref to persist it between rerenders to update the value in the current focused input
  const otpValueRef = useRef(value ? value.toString().split("") : Array(numInputs));

  const isInputNum = inputType === "number" || inputType === "tel";

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
  }, [numInputs]);

  useEffect(() => {
    if (shouldAutoFocus) {
      inputRefs.current[0]?.focus();
    }
  }, [shouldAutoFocus]);

  // On each Rerender check if the value is an empty string we reset the otpValueRef value
  if (value.trim() === "") {
    otpValueRef.current = Array(numInputs);
  }

  const getPlaceholderValue = () => {
    if (typeof placeholder === "string") {
      if (placeholder.length === numInputs) {
        return placeholder;
      }

      if (placeholder.length > 0) {
        console.error("Length of the placeholder should be equal to the number of inputs.");
      }
    }
    return undefined;
  };

  const isInputValueValid = (value: string) => {
    const isTypeValid = isInputNum ? !isNaN(Number(value)) : typeof value === "string";
    return isTypeValid && value.trim().length === 1;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
      focusInput(activeInput + 1);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { nativeEvent } = event;
    const value = event.target.value;
    console.log(event);
    if (!isInputValueValid(value)) {
      // Pasting from the native autofill suggestion on a mobile device can pass
      // the pasted string as one long input to one of the cells. This ensures
      // that we handle the full input and not just the first character.

      if (value.length > 1) {
        const valueArr = value.split("", numInputs);
        const hasInvalidInput = valueArr.some(cellInput => !isInputValueValid(cellInput));
        if (!hasInvalidInput) {
          handleOTPChange(valueArr);
          focusInput(value.length - 1);
          otpValueRef.current = valueArr;
        }
      }

      // @ts-expect-error - This was added previously to handle and edge case
      // for dealing with keyCode "229 Unidentified" on Android. Check if this is
      // still needed.
      if (nativeEvent.data === null && nativeEvent.inputType === "deleteContentBackward") {
        event.preventDefault();
        changeCodeAtFocus("");
        focusInput(activeInput - 1);
      }

      // Clear the input if it's not valid value because firefox allows
      // pasting non-numeric characters in a number type input
      event.target.value = "";
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => (index: number) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const currentOtpValue = otpValueRef.current;

    if ([event.code, event.key].includes("Backspace")) {
      event.preventDefault();
      changeCodeAtFocus("");
      focusInput(activeInput - 1);
    } else if (event.code === "Delete") {
      event.preventDefault();
      changeCodeAtFocus("");
    } else if (event.code === "ArrowLeft") {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === "ArrowRight") {
      event.preventDefault();
      focusInput(activeInput + 1);
    }
    // React does not trigger onChange when the same value is entered
    // again. So we need to focus the next input manually in this case.
    else if (event.key === currentOtpValue[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === "Spacebar" ||
      event.code === "Space" ||
      event.code === "ArrowUp" ||
      event.code === "ArrowDown"
    ) {
      event.preventDefault();
    }
  };

  const focusInput = (index: number) => {
    const activeInput = Math.max(Math.min(numInputs - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      inputRefs.current[activeInput]?.select();
      setActiveInput(activeInput);
    }
  };

  const changeCodeAtFocus = (value: string) => {
    const currentOtpValue = otpValueRef.current;
    currentOtpValue[activeInput] = value[0];

    handleOTPChange(currentOtpValue);
  };

  const handleOTPChange = (otp: Array<string>) => {
    const otpValue = otp.join("");
    onChange(otpValue);
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const currentOtpValue = otpValueRef.current;
    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = event.clipboardData.getData("text/plain").slice(0, numInputs).split("");

    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (isInputNum && pastedData.some(value => isNaN(Number(value)))) {
      return;
    }

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pastedData.length > 0) {
        currentOtpValue[pos] = pastedData.shift() ?? "";
        nextActiveInput++;
      }
    }

    focusInput(nextActiveInput);
    handleOTPChange(currentOtpValue);
  };

  return (
    <div
      style={Object.assign(
        { display: "flex", alignItems: "center" },
        isStyleObject(containerStyle) && containerStyle
      )}
      className={typeof containerStyle === "string" ? containerStyle : undefined}
      onPaste={onPaste}
    >
      {Array.from({ length: numInputs }, (_, index) => index).map(index => (
        <Fragment key={index}>
          {renderInput(
            {
              value: otpValueRef.current[index] ?? "",
              placeholder: getPlaceholderValue()?.[index] ?? undefined,
              ref: element => (inputRefs.current[index] = element),
              onChange: handleChange,
              onFocus: event => handleFocus(event)(index),
              onBlur: handleBlur,
              onKeyDown: handleKeyDown,
              onPaste: handlePaste,
              autoComplete: "off",
              "aria-label": `Please enter OTP character ${index + 1}`,
              style: Object.assign(
                !skipDefaultStyles ? ({ width: "1em", textAlign: "center" } as const) : {},
                isStyleObject(inputStyle) ? inputStyle : {}
              ),
              className: typeof inputStyle === "string" ? inputStyle : undefined,
              type: inputType,
              inputMode: isInputNum ? "numeric" : "text",
              onInput: handleInputChange,
              disabled,
            },
            index
          )}
          {index < numInputs - 1 &&
            (typeof renderSeparator === "function" ? renderSeparator(index) : renderSeparator)}
        </Fragment>
      ))}
    </div>
  );
};

export type { OTPInputProps, InputProps, AllowedInputTypes };
export default OTPInput;
