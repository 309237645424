import { Outlet } from "react-router-dom";
import MainHeader from "../MainHeader/MainHeader";
import MainFooter from "../MainFooter/MainFooter";

import classes from "./MainLayout.module.css";

export default function MainLayout() {
  return (
    <>
      <MainHeader />
      <main className={classes.mainContainer}>
        <Outlet />
      </main>
      <MainFooter />
    </>
  );
}
