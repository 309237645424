import FacebookLogo from "@/assets/images/path.svg?react";
import useUserStore from "@/store/UserStore";
import type { UserData } from "@/types";
import { FACEBOOK_CLIENT_ID } from "@/utils/Constants";
import { getUserInfoFromFbLogin } from "@/utils/DataFetchers";
import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import classes from "./FacebookAuthBtn.module.css";

export default function FacebookAuthBtn({
  setErrorMsg,
}: {
  setErrorMsg: Dispatch<
    SetStateAction<{
      type: string;
      msg: string;
    }>
  >;
}) {
  const { setUser } = useUserStore(state => state);

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const { t } = useTranslation();

  const { mutate, isPending } = useMutation({
    mutationFn: getUserInfoFromFbLogin,
    onSuccess: (response: UserData) => {
      setErrorMsg({ type: "facebook", msg: "" });
      if (!response) throw new Error("No response");
      const { user_first_name, user_last_name, user_email, user_id } = response;
      setUser({
        userId: user_id,
        userFirstName: user_first_name,
        userLastName: user_last_name,
        userEmail: user_email,
        isAuthenticated: true,
      });
    },
    onError: error => {
      setErrorMsg({ type: "facebook", msg: error.message });
    },
  });

  useEffect(() => {
    if (searchParams.has("code")) {
      const fbCode = searchParams.get("code");
      if (!fbCode) return;
      mutate({ fbCode, redirectUri: `${window.location.origin}${location.pathname}` });
    }
  }, [searchParams, mutate, location.pathname]);

  return (
    <>
      <Link
        to={`https://www.facebook.com/v20.0/dialog/oauth?client_id=${FACEBOOK_CLIENT_ID}&redirect_uri=${window.location.origin}${location.pathname}`}
        className={`${classes.facebookBtn} ${isPending ? classes.disabled : ""}`}
      >
        <FacebookLogo />
        <p>{t("continue_with_facebook_label")}</p>
      </Link>
    </>
  );
}
