import Button from "@/Components/Button/Button";
import InputBox from "@/Components/InputBox/InputBox";
import MainForm from "@/Components/MainForm/MainForm";
import { useTranslation } from "react-i18next";

export default function UserNameForm({
  firstNameValue,
  lastNameValue,
  isPending,
  isFirstNameValid,
  isLastNameValid,
  nameErrorMsg,
  submitHandler,
  setFirstNameValue,
  setLastNameValue,
  setNameErrorMsg: setErrorMsg,
}: {
  firstNameValue: string;
  lastNameValue: string;
  isPending: boolean;
  isFirstNameValid: boolean;
  isLastNameValid: boolean;
  nameErrorMsg: {
    firstNameError: string;
    lastNameError: string;
  };
  submitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
  setFirstNameValue: React.Dispatch<React.SetStateAction<string>>;
  setLastNameValue: React.Dispatch<React.SetStateAction<string>>;
  setNameErrorMsg: React.Dispatch<
    React.SetStateAction<{
      firstNameError: string;
      lastNameError: string;
    }>
  >;
}) {
  const { t } = useTranslation();

  return (
    <MainForm title={t("sign_up_label")} method='post' onSubmit={submitHandler}>
      <InputBox
        variant='mainInput'
        type='text'
        name='first_name'
        placeholder={t("first_name_label")}
        value={firstNameValue}
        disabled={isPending}
        autoFocus
        onChange={e => {
          setFirstNameValue(e.currentTarget.value);
          setErrorMsg(prev => ({
            ...prev,
            firstNameError: "",
          }));
        }}
        onBlur={() => {
          setErrorMsg(prev => ({
            ...prev,
            firstNameError:
              firstNameValue.trim() !== "" && !isFirstNameValid
                ? "Name should be more than 2 characters and less than 50 characters."
                : "",
          }));
        }}
        errorMsg={nameErrorMsg.firstNameError}
      />
      <InputBox
        variant='mainInput'
        type='text'
        name='last_name'
        placeholder={t("last_name_label")}
        value={lastNameValue}
        disabled={isPending}
        onChange={e => {
          setLastNameValue(e.currentTarget.value);
          setErrorMsg(prev => ({
            ...prev,
            lastNameError: "",
          }));
        }}
        onBlur={() => {
          setErrorMsg(prev => ({
            ...prev,
            lastNameError:
              lastNameValue.trim() !== "" && !isLastNameValid
                ? "Name should be more than 2 characters and less than 50 characters."
                : "",
          }));
        }}
        errorMsg={nameErrorMsg.lastNameError}
      />
      <Button
        variant='green'
        type='submit'
        disabled={
          isPending ||
          !isFirstNameValid ||
          !isLastNameValid ||
          firstNameValue.trim() === "" ||
          lastNameValue.trim() === ""
        }
      >
        {t("sign_up_label")}
      </Button>
    </MainForm>
  );
}
