import ChallengeItem from "@/Components/ChallengeItem/ChallengeItem";
import EmptyView from "@/Components/EmptyView/EmptyView";
import flagOrange from "@/assets/images/challenge-active@3x.png";
import flag from "@/assets/images/challenge@3x.png";
import explainer from "@/assets/images/explainer-icon@3x.png";
import type { ChapterData, ResponseError } from "@/types";
import { getChapterData } from "@/utils/DataFetchers";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import classes from "./ChapterPage.module.css";

export default function ChapterPage() {
  const pathname = useLocation().pathname;
  const { chapter_id } = useParams();
  const {
    i18n: { language },
  } = useTranslation();
  if (!chapter_id) {
    throw new Error("Chapter ID is required");
  }

  const {
    data: chapterData,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<ChapterData, ResponseError | Error>({
    queryKey: ["challenges", chapter_id, language],
    queryFn: getChapterData,
  });

  if (error) {
    return <EmptyView refetch={refetch} />;
  }

  return (
    <>
      {isLoading || isFetching ? (
        <></>
      ) : (
        <section className={`container-80 ${classes.challengesSection}`}>
          <div>
            <h1 className={classes.challengesTitle}>{chapterData?.chapter_title}</h1>
            <p className={classes.challengesText}>{chapterData?.chapter_description}</p>
          </div>
          <ul className={classes.challengesList}>
            <ChallengeItem
              link={`${pathname}explainer/`}
              text={chapterData?.explainer?.explainer_title ?? ""}
              iconUrl={explainer}
              className={classes.challengeItem}
            />
            {chapterData?.challenges?.map(challenge => (
              <ChallengeItem
                key={challenge.challenge_id}
                link={`challenge/${challenge.challenge_id}/`}
                text={challenge.challenge_title}
                iconUrl={challenge.challenge_taken ? flagOrange : flag}
                className={classes.challengeItem}
              />
            ))}
          </ul>
        </section>
      )}
    </>
  );
}
