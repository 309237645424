import MainFooter from "../MainFooter/MainFooter";
import { Outlet } from "react-router-dom";

import classes from "./SecondaryLayout.module.css";

export default function SecondaryLayout() {
  return (
    <>
      <main className={classes.mainContainer}>
        <Outlet />
      </main>
      <MainFooter />
    </>
  );
}
