import { ROUTES } from "@/utils/Network";
import { fetchData } from "@/utils/Utils";

export async function AuthLoader() {
  try {
    return await fetchData(ROUTES.getUser.url, {
      method: ROUTES.getUser.method,
    });
  } catch (err) {
    if (err instanceof Error) console.error(err.message);
    return null;
  }
}
