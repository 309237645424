import { Form, FormProps } from "react-router-dom";

import classes from "./MainForm.module.css";

interface MainFormProps extends FormProps {
  title: string;
  text?: string;
}

export default function MainForm({
  children,
  title,
  text,
  ...rest
}: MainFormProps) {
  return (
    <div>
      <h1 className={classes.formTitle}>{title}</h1>
      {text && <p className={classes.formText}>{text}</p>}
      <Form className={classes.formElement} {...rest}>
        {children}
      </Form>
    </div>
  );
}
