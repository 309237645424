import ErrorPage from "@/routes/ErrorPage/ErrorPage";
import type { ChallengeData, ResponseError } from "@/types";
import { getChallengeData } from "@/utils/DataFetchers";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Outlet, useParams } from "react-router-dom";
import ChallengeHeader from "../ChallengeHeader/ChallengeHeader";

import { useTranslation } from "react-i18next";
import classes from "./ChallengeLayout.module.css";

export default function SecondaryLayout() {
  const { challenge_id } = useParams();
  const {
    i18n: { language },
  } = useTranslation();

  const { data: challengeData, error } = useSuspenseQuery<ChallengeData, Error | ResponseError>({
    queryKey: ["challenge", challenge_id, language],
    queryFn: getChallengeData,
  });

  if (error) return <ErrorPage />;

  return (
    <>
      <ChallengeHeader title={challengeData?.challenge_title || ""} />
      <main className={classes.mainContainer}>
        <Outlet />
      </main>
    </>
  );
}
