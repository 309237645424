import DownLoadIcon from "@/assets/images/download.svg?react";
import { portalElement } from "@/utils/Utils";
import { ComponentProps, RefObject } from "react";
import { useTranslation } from "react-i18next";

import classes from "./Modal.module.css";

interface ModalProps extends ComponentProps<"dialog"> {
  downloadCanvasAsImage?: () => void;
  rejectInput?: () => void;
  variant: "show-header" | "headless" | "";
  modalRef: RefObject<HTMLDialogElement>;
  dragBtnRef?: RefObject<HTMLSpanElement>;
  outsideClickToClose?: boolean;
  pressEscToClose?: boolean;
}

const Modal = ({
  children,
  className,
  variant,
  modalRef,
  dragBtnRef,
  outsideClickToClose = true,
  pressEscToClose = true,
  downloadCanvasAsImage,
  rejectInput,
  ...rest
}: ModalProps) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <>
      {portalElement(
        <dialog
          ref={modalRef}
          className={className ? className : ""}
          onClick={e => {
            if (e.target === e.currentTarget && outsideClickToClose) {
              e.currentTarget.close();
            }
          }}
          onKeyDown={e => {
            if (e.key === "Escape" && !pressEscToClose) {
              e.preventDefault();
            }
          }}
          dir={language === "ar" ? "rtl" : "ltr"}
          {...rest}
        >
          <div className={classes.dialogWrapper}>
            {variant === "show-header" && (
              <div className={classes.headerWrapper}>
                <span className={classes.dragHead} ref={dragBtnRef}></span>
                <div className={classes.btnsWrapper}>
                  <button
                    title='arrow icon'
                    type='button'
                    className='arrow-to-bottom'
                    onClick={downloadCanvasAsImage}
                  >
                    <DownLoadIcon />
                  </button>
                  <button
                    title='Close (Esc)'
                    type='button'
                    className={classes.closeBtn}
                    onClick={() => {
                      modalRef.current?.close();
                      if (rejectInput) {
                        rejectInput();
                      }
                    }}
                  >
                    <span className='close-btn md'></span>
                  </button>
                </div>
              </div>
            )}
            {children}
          </div>
        </dialog>,
        document.getElementById("modalContainer")
      )}
    </>
  );
};

export default Modal;
