import { create } from "zustand";

type UserState = {
  user: {
    userId: number | null;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    isAuthenticated: boolean | null;
    subscriptionDetails?: {
      start_date: string;
      end_date: string;
    };
  } | null;
};

type UserActions = {
  setUser: (user: UserState["user"]) => void;
};

type UserStore = UserState & UserActions;

const useUserStore = create<UserStore>(set => ({
  user: null,
  setUser: user => set({ user }),
}));

export default useUserStore;
