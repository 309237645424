import PrivacyPageArContent from "@/Components/UserPrivacy/PrivacyPageContent/PrivacyPageArContent";
import PrivacyPageEnContent from "@/Components/UserPrivacy/PrivacyPageContent/PrivacyPageEnContent";
import { useTranslation } from "react-i18next";

export default function PrivacyPage() {
  const {
    i18n: { language },
  } = useTranslation();
  return <>{language === "ar" ? <PrivacyPageArContent /> : <PrivacyPageEnContent />};</>;
}
