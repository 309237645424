import PhoneNumberForm from "@/Components/PhoneNumberForm/PhoneNumberForm";
import { Language } from "@/types";
import { PAYMENT_CLIENT_ID, SUBSCRIPTION_AMOUNT } from "@/utils/Constants";
import { payment } from "@/utils/DataFetchers";
import { useMutation } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

export default function FawryNumberPage() {
  const [errorMsg, setErrorMsg] = useState("");

  const { state } = useLocation();

  const {
    i18n: { language },
  } = useTranslation();

  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationKey: ["fawryMutation"],
    mutationFn: payment,
    onSuccess: () => {
      setErrorMsg("");
      navigate("./../fawry_payment_details", { state: "fawryDetails" });
    },
    onError: error => {
      setErrorMsg(error.message);
    },
  });

  if (state !== "fawryOption") {
    return <Navigate to={`/${language}/payment`} />;
  }

  const mobileNumberSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMsg("");
    let formattedPhoneNumber;
    const formData = new FormData(e.currentTarget);
    const phoneNumber = formData.get("phoneNumber") as string | null;
    if (!phoneNumber) return;
    // CHECK IF THE MOBILE NUMBER STARTS WITH 0 THEN SEND IT ELSE ADD THE 0 TO THE START
    if (phoneNumber.startsWith("0")) {
      formattedPhoneNumber = phoneNumber;
    } else {
      formattedPhoneNumber = `0${phoneNumber}`;
    }

    mutate({
      type: "fawry",
      amount: SUBSCRIPTION_AMOUNT,
      clientId: PAYMENT_CLIENT_ID,
      mobile: formattedPhoneNumber,
      language: language as Language,
    });
  };
  return (
    <section className='container-50 form-wrapper'>
      <PhoneNumberForm
        submitHandler={mobileNumberSubmitHandler}
        setErrorMsg={setErrorMsg}
        errorMsg={errorMsg}
        isPending={isPending}
      />
    </section>
  );
}
