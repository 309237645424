import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function DropDownMenuWrapper({
  children,
  menuBtnContent,
  menuBtnTitle,
  menuWrapperClassName,
  menuBtnClassName,
}: {
  children: JSX.Element;
  menuBtnContent: ReactNode;
  menuBtnTitle?: string;
  menuWrapperClassName: string | undefined;
  menuBtnClassName: string | undefined;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const pathname = useLocation().pathname;

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      const target = e.target;
      if (
        target &&
        target instanceof HTMLElement &&
        !target.closest(`.${menuWrapperClassName}`) &&
        isMenuOpen
      ) {
        setIsMenuOpen(false);
      }
    };
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape" && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", clickHandler);
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [isMenuOpen, menuWrapperClassName]);

  return (
    <div className={menuWrapperClassName}>
      <button
        className={menuBtnClassName}
        onClick={setIsMenuOpen.bind(null, prev => !prev)}
        type='button'
        title={menuBtnTitle || "menu button"}
      >
        {menuBtnContent}
      </button>
      {isMenuOpen && <div>{children}</div>}
    </div>
  );
}
