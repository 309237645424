import { create } from "zustand";
import { persist } from "zustand/middleware";

type CodeState = {
  code: { [challengeId: string]: string };
};

type CodeActions = {
  updateCode: (currentCode: { [challengeId: string]: string }) => void;
};

type CodeStore = CodeState & CodeActions;

const useCodeStore = create<CodeStore>()(
  persist(
    (set, get) => ({
      code: {},
      updateCode: currentCode => set(() => ({ code: { ...get().code, ...currentCode } })),
    }),
    {
      name: "code-storage",
    }
  )
);

export default useCodeStore;
