import LangIcon from "@/assets/images/language.svg?react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import classes from "./LanguageBtn.module.css";

export default function LanguageBtn() {
  const { pathname } = useLocation();
  const { lang } = useParams();
  const { t } = useTranslation();

  const currentPathWithoutLang = pathname.split("/").slice(2).join("/");

  const newLang = lang === "en" ? "ar" : "en";

  return (
    <Link to={`/${newLang}/${currentPathWithoutLang}`} className={classes.langBtn} replace>
      {t(newLang === "ar" ? "arabic_language_label" : "english_language_label")}
      <LangIcon />
    </Link>
  );
}
