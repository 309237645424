import { Link } from "react-router-dom";

import classes from "./ChallengeItem.module.css";

export default function ChallengeItem({
  iconUrl,
  link,
  className,
  text,
}: {
  iconUrl: string;
  link: string;
  className?: string;
  text: string;
}) {
  return (
    <li className={`${classes.challengeItem} ${className ? className : ""}`}>
      <Link to={link} className={classes.linkItem}>
        <img
          src={iconUrl}
          alt="challenge icon"
          className={classes.icon}
          draggable={false}
        />
        <p>{text}</p>
        <span className="chevron right"></span>
      </Link>
    </li>
  );
}
