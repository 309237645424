import Button from "@/Components/Button/Button";
import MainForm from "@/Components/MainForm/MainForm";
import OtpInput from "@/Components/React-otp-input/react-otp-input";
import { formateSeconds } from "@/utils/Utils";
import { Dispatch, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import classes from "./OtpForm.module.css";

export default function OtpForm({
  otpValue,
  isPending,
  remainingDuration,
  otpTimers,
  resendingPending,
  otpErrorMsg,
  setErrorMsg,
  setOtpValue,
  otpSubmitHandler,
  resendHandler,
}: {
  otpValue: string;
  isPending: boolean;
  remainingDuration: number;
  otpTimers: { duration: number; resendingDuration: number };
  resendingPending: boolean;
  otpErrorMsg: string;
  setErrorMsg: Dispatch<React.SetStateAction<string>>;
  setOtpValue: Dispatch<React.SetStateAction<string>>;
  otpSubmitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
  resendHandler: (e: MouseEvent) => void;
}) {
  const { t } = useTranslation();

  return (
    <MainForm
      title={t("verification_code_label")}
      text={t("verification_code_hint_message")}
      onSubmit={otpSubmitHandler}
    >
      <OtpInput
        value={otpValue}
        onChange={otp => {
          setOtpValue(otp);
          setErrorMsg("");
        }}
        numInputs={6}
        skipDefaultStyles
        containerStyle={classes.otpContainer}
        inputStyle={classes.otpField}
        shouldAutoFocus={true}
        inputType='number'
        disabled={isPending}
        renderInput={props => <input {...props} />}
      />
      {resendingPending || <p className={classes.errorMsg}>{otpErrorMsg}</p>}
      <div className={classes.actionsContainer}>
        <div className={classes.resendContainer}>
          <button
            onClick={resendHandler}
            disabled={
              !(remainingDuration <= otpTimers.duration - otpTimers.resendingDuration) ||
              resendingPending
            }
            type='button'
          >
            {t("resend_verification_code_label")}
          </button>
          {remainingDuration <= 0 || resendingPending || (
            <span>{formateSeconds(remainingDuration)}</span>
          )}
        </div>
        <Button variant='green' type='submit' disabled={otpValue.length < 6 || isPending}>
          {t("verify_button")}
        </Button>
      </div>
    </MainForm>
  );
}
