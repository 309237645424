import classes from "./Button.module.css";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: "green" | "blue" | "grey" | "";
}

export default function Button({ variant, children, className, ...rest }: ButtonProps) {
  return (
    <button
      className={`${classes.btn} ${classes[variant]} ${className ? className : ""}`}
      {...rest}
    >
      {children}
    </button>
  );
}
