import type { AITextToAudioRequest, Language, PaymentRequestBody, RunCodeRequest } from "@/types";

import { QueryKey } from "@tanstack/react-query";
import { ROUTES } from "./Network";
import { fetchData } from "./Utils";

// #region SOCIAL LOGIN
// FETCH USER INFORMATION FROM FACEBOOK LOGIN
export const getUserInfoFromFbLogin = async ({
  fbCode,
  redirectUri,
}: {
  fbCode: string;
  redirectUri: string;
}) =>
  await fetchData(ROUTES.faceBookLogin.url, {
    method: ROUTES.faceBookLogin.method,
    body: JSON.stringify({
      code: fbCode,
      redirect_uri: redirectUri,
    }),
  });

// FETCH USER INFORMATION FROM GOOGLE LOGIN
export const getUserInfo = async ({ googleCode }: { googleCode: string }) =>
  await fetchData(ROUTES.googleLogin.url, {
    method: ROUTES.googleLogin.method,
    body: JSON.stringify({ code: googleCode }),
  });

// #endregion SOCIAL LOGIN

// #region CHALLENGE ACTIONS
// RUN CODE FOR A CHALLENGE
export const runCode = async ({
  courseId,
  chapterId,
  challengeId,
  code,
  output,
  errorMessage,
}: RunCodeRequest) =>
  await fetchData(ROUTES.runCode.url, {
    method: ROUTES.runCode.method,
    body: JSON.stringify({
      challenge_id: challengeId,
      chapter_id: chapterId,
      course_id: courseId,
      code,
      output,
      error: errorMessage,
    }),
  });

// GET AUDIO RESPONSE FOR A CHAT COMPLETION CHUNK
export const getResponseAudio = async ({
  chatCompletionChunk,
  courseId,
  chapterId,
  challengeId,
}: AITextToAudioRequest) =>
  await fetch(ROUTES.needHelpAudio.url, {
    method: ROUTES.needHelpAudio.method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      input: chatCompletionChunk,
      course_id: courseId,
      chapter_id: chapterId,
      challenge_id: challengeId,
    }),
  });

// #endregion CHALLENGE ACTIONS

// #region USER DATA

// LOG OUT THE USER
export const logOut = async () =>
  await fetchData(ROUTES.signOut.url, {
    method: ROUTES.signOut.method,
  });

// DELETE USER
export const deleteUser = async () =>
  await fetchData(ROUTES.deleteUser.url, {
    method: ROUTES.deleteUser.method,
  });

// SEND EMAIL WITH USER EMAIL
export const sendEmail = async ({ user_email }: { user_email: string }) =>
  await fetchData(ROUTES.requestOtp.url, {
    method: ROUTES.requestOtp.method,
    body: JSON.stringify({
      user_email,
    }),
  });

// VERIFY OTP WITH OTP AND USER EMAIL
export const verifyOtp = async ({ otp, user_email }: { otp: string; user_email: string }) =>
  await fetchData(ROUTES.verifyOtp.url, {
    method: ROUTES.verifyOtp.method,
    body: JSON.stringify({
      otp,
      user_email,
    }),
  });

// SEND USER NAME WITH USER EMAIL, FIRST NAME, AND LAST NAME
export const sendName = async ({
  user_email,
  user_first_name,
  user_last_name,
}: {
  user_email: string;
  user_first_name: string;
  user_last_name: string;
}) =>
  await fetchData(ROUTES.createUser.url, {
    method: ROUTES.createUser.method,
    body: JSON.stringify({
      user_email,
      user_first_name,
      user_last_name,
    }),
  });

//#endregion USER DATA

// #region CONTENT DATA

// GET COURSES DATA
export const getCoursesData = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [_, __, lang] = queryKey as [string, boolean, Language];
  return await fetchData(ROUTES.courses.url(lang), {
    method: ROUTES.courses.method,
  });
};

// GET CHAPTER DATA WITH QUERY KEY
export const getChapterData = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [_, chapter_id, lang] = queryKey as [string, string, Language];
  return await fetchData(ROUTES.chapter.url(chapter_id, lang), {
    method: ROUTES.chapter.method,
  });
};

// GET EXPLAINER DATA WITH QUERY KEY
export const getExplainerData = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [_, chapter_id, lang] = queryKey as [string, string, Language];
  return await fetchData(ROUTES.explainer.url(chapter_id, lang), {
    method: ROUTES.explainer.method,
  });
};

// GET CHALLENGE DATA WITH QUERY KEY
export const getChallengeData = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [_, challenge_id, lang] = queryKey as [string, string, Language];
  return await fetchData(ROUTES.challenge.url(challenge_id, lang), {
    method: ROUTES.challenge.method,
  });
};
// #endregion CONTENT DATA

// #region PAYMENT
export const payment = async (paymentData: PaymentRequestBody) => {
  if (paymentData.type === "fawry") {
    const { type, amount, clientId, mobile, language } = paymentData;
    return await fetchData(ROUTES.payment.url, {
      method: ROUTES.payment.method,
      body: JSON.stringify({
        type,
        amount,
        clientId,
        mobile,
        language,
      }),
    });
  } else {
    const { type, amount, clientId, language, successReturnUrl, failureReturnUrl } = paymentData;
    return await fetchData(ROUTES.payment.url, {
      method: ROUTES.payment.method,
      body: JSON.stringify({
        type,
        amount,
        clientId,
        language,
        successReturnUrl,
        failureReturnUrl,
      }),
    });
  }
};

// #endregion
