import EnvelopeIcon from "@/assets/images/envelope.svg?react";
import FacebookAuthBtn from "@/Components/AuthOptions/FacebookAuthBtn/FacebookAuthBtn";
import GoogleAuthBtn from "@/Components/AuthOptions/GoogleAuthBtn/GoogleAuthBtn";
import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";

import classes from "./AuthOptions.module.css";

export default function AuthOptions({
  setStep,
}: {
  setStep: Dispatch<React.SetStateAction<number>>;
}) {
  const [error, setError] = useState({ type: "", msg: "" });

  const { t } = useTranslation();

  return (
    <>
      <h1 className={classes.authSectionTitle}>{t("welcome_to_codebites_label")}</h1>
      <ul className={classes.AuthOptionsList}>
        <li>
          <GoogleAuthBtn setErrorMsg={setError} />
          <p className={classes.errorMsg}>{error.type === "google" && error.msg}</p>
        </li>
        <li>
          <FacebookAuthBtn setErrorMsg={setError} />
          <p className={classes.errorMsg}>{error.type === "facebook" && error.msg}</p>
        </li>
        <li>
          <button
            type='button'
            className={classes.emailBtn}
            onClick={() => {
              if (setStep) setStep(prev => ++prev);
            }}
          >
            <EnvelopeIcon />
            <p>{t("continue_with_email_label")}</p>
          </button>
        </li>
      </ul>
    </>
  );
}
