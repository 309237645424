import DeleteUser from "@/assets/images/deleteUser.svg?react";
import Button from "@/Components/Button/Button";
import Modal from "@/Components/Modal";
import useUserStore from "@/store/UserStore";
import { deleteUser } from "@/utils/DataFetchers";
import { useMutation } from "@tanstack/react-query";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import classes from "./MyAccountPage.module.css";

export default function MyAccountPage() {
  const deleteDialogRef = useRef<HTMLDialogElement>(null);

  const { user, setUser } = useUserStore(state => state);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { mutate: deleteUserMutate, isPending } = useMutation({
    mutationKey: ["deleteUser"],
    mutationFn: deleteUser,
    onSuccess() {
      setUser(null);
      navigate("/");
    },
    onError(error) {
      console.log(error.message);
    },
  });

  return (
    <section>
      <Modal variant='headless' modalRef={deleteDialogRef} className={classes.deleteDialog}>
        <div className={classes.contentWrapper}>
          <h3>{t("delete_account_label")}</h3>
          <p>{t("delete_account_confirmation_message")}</p>
          <div className={classes.btnsContainer}>
            <Button
              variant=''
              onClick={() => {
                deleteDialogRef.current?.close();
              }}
              type='button'
            >
              {t("cancel_label")}
            </Button>
            <Button
              variant='green'
              onClick={() => {
                deleteUserMutate();
              }}
              disabled={isPending}
              type='button'
            >
              {t("confirm_label")}
            </Button>
          </div>
        </div>
      </Modal>
      <div className='container-125'>
        <div className={classes.myAccountHeader}>
          <h1>{t("my_account_label")}</h1>
          <button
            disabled={isPending}
            onClick={() => {
              deleteDialogRef.current?.showModal();
            }}
            type='button'
          >
            <DeleteUser />
            <span>{t("delete_account_label")}</span>
          </button>
        </div>
        <ul className={classes.userDetails}>
          <li>
            <label>{t("first_name_label")}</label>
            <input disabled type='text' defaultValue={user?.userFirstName} />
          </li>
          <li>
            <label>{t("last_name_label")}</label>
            <input disabled type='text' defaultValue={user?.userLastName} />
          </li>
          <li>
            <label>{t("email_address_label")}</label>
            <input disabled type='text' defaultValue={user?.userEmail} />
          </li>
        </ul>
      </div>
    </section>
  );
}
