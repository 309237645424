import EmptyIcon from "@/assets/images/illustrationEmpty.svg?react";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";

import classes from "./EmptyView.module.css";

export default function EmptyView({
  refetch,
}: {
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult>;
}) {
  const { t } = useTranslation();

  return (
    <section className={classes.emptyViewSection}>
      <div className='container-125'>
        <EmptyIcon />
        <p>{t("something_went_wrong_error_message")}</p>
        <Button
          variant='green'
          onClick={() => {
            refetch();
          }}
          type='button'
        >
          {t("retry_label")}
        </Button>
      </div>
    </section>
  );
}
