import EgyptFlag from "@/assets/images/egypt.svg?react";
import { ChangeEvent, Dispatch, FormEventHandler, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import Button from "../Button/Button";
import InputBox from "../InputBox/InputBox";
import MainForm from "../MainForm/MainForm";

import classes from "./PhoneNumberForm.module.css";

const phoneNumberSchema = z
  .string()
  .min(10)
  .max(11)
  .regex(/^(01\d{9}|1\d{9})$/);

export default function PhoneNumberForm({
  submitHandler,
  setErrorMsg,
  errorMsg,
  isPending,
}: {
  submitHandler: FormEventHandler;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  errorMsg: string;
  isPending: boolean;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    const value = e.currentTarget.value;
    if (value.startsWith("1")) {
      if (value.length <= 10 || !value) setValue(value);
    } else {
      if (value.length <= 11 || !value) setValue(value);
    }
  };

  const parsedNumber = phoneNumberSchema.safeParse(value);

  return (
    <MainForm title={t("pay_with_fawry_label")} onSubmit={submitHandler}>
      <div className={classes.inputWrapper} dir='ltr'>
        <label htmlFor='phoneNumber'>
          <EgyptFlag />
          <span>+20</span>
        </label>
        <InputBox
          id='phoneNumber'
          name='phoneNumber'
          type='number'
          variant='mainInput'
          placeholder={t("mobile_number_label")}
          value={value}
          onChange={changeHandler}
          autoFocus
          onBlur={() => {
            if (!parsedNumber.success && value.length > 0) {
              setErrorMsg("Please enter a valid egyptian mobile phone number");
            }
          }}
          errorMsg={errorMsg}
          disabled={isPending}
        />
      </div>

      <Button type='submit' variant='green' disabled={!parsedNumber.success || isPending}>
        {t("continue_label")}
      </Button>
    </MainForm>
  );
}
