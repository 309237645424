import classes from "./InputBox.module.css";

interface InputBoxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  variant: "mainInput" | "";
  errorMsg?: string;
}

export default function InputBox({
  placeholder,
  type,
  errorMsg,
  className,
  ...rest
}: InputBoxProps) {
  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        className={`${classes.mainInput} ${className ? className : ""}`}
        {...rest}
      />
      <p className={classes.errorMsg}>{errorMsg}</p>
    </>
  );
}
