import { Link, LinkProps } from "react-router-dom";

import classes from "./LinkBtn.module.css";

interface LinkBtnProp extends LinkProps {
  variant: "signIn" | "signUp" | "payment" | "";
  className?: string;
}

export default function LinkBtn({ children, variant, className, ...rest }: LinkBtnProp) {
  return (
    <Link className={`${classes.cta} ${classes[variant]} ${className ? className : ""}`} {...rest}>
      {children}
    </Link>
  );
}
