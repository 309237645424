import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

export default function LanguageLayout() {
  const { lang } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      await i18n.changeLanguage(lang);
      document.documentElement.lang = lang ?? "en";
      document.getElementById("root")!.dir = lang === "ar" ? "rtl" : "ltr";
    })();
  }, [lang, i18n]);

  return <Outlet />;
}
