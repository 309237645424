import GoogleLogo from "@/assets/images/google-brands.svg?react";
import useUserStore from "@/store/UserStore";
import type { ResponseError, UserData } from "@/types";
import { getUserInfo } from "@/utils/DataFetchers";
import { useGoogleLogin } from "@react-oauth/google";
import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import classes from "./GoogleAuthBtn.module.css";

export default function GoogleAuthBtn({
  setErrorMsg,
}: {
  setErrorMsg: Dispatch<
    SetStateAction<{
      type: string;
      msg: string;
    }>
  >;
}) {
  const [isGoogleWindowOpen, setIsGoogleWindowOpen] = useState(false);

  const { setUser } = useUserStore(state => state);

  const { t } = useTranslation();

  const handleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: GoogleResponse => {
      mutate({ googleCode: GoogleResponse.code });
      setIsGoogleWindowOpen(false);
    },
    onError: error => {
      console.log("Login Failed:", error);
      setIsGoogleWindowOpen(false);
    },
    onNonOAuthError: () => {
      setIsGoogleWindowOpen(false);
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: getUserInfo,
    onSuccess: ({ user_id, user_first_name, user_last_name, user_email }: UserData) => {
      setErrorMsg({ type: "google", msg: "" });
      setUser({
        userId: user_id,
        userFirstName: user_first_name,
        userLastName: user_last_name,
        userEmail: user_email,
        isAuthenticated: true,
      });
    },
    onError: (error: ResponseError | Error) => {
      setErrorMsg({ type: "google", msg: error.message });
    },
  });

  return (
    <button
      type='button'
      className={classes.googleBtn}
      disabled={isPending || isGoogleWindowOpen}
      onClick={() => {
        handleLogin();
        setIsGoogleWindowOpen(true);
      }}
    >
      <GoogleLogo />
      <p>{t("continue_with_google_label")}</p>
    </button>
  );
}
