import Button from "@/Components/Button/Button";
import InputBox from "@/Components/InputBox/InputBox";
import MainForm from "@/Components/MainForm/MainForm";
import { useTranslation } from "react-i18next";

export default function EmailForm({
  inputValue,
  errorMsg,
  isEmailValid,
  isPending,
  setInputValue,
  setErrorMsg,
  submitHandler,
}: {
  inputValue: string;
  errorMsg: string;
  isEmailValid: boolean;
  isPending: boolean;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  submitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
}) {
  const { t } = useTranslation();

  return (
    <MainForm title={t("continue_with_email_label")} method='post' onSubmit={submitHandler}>
      <InputBox
        variant='mainInput'
        placeholder={t("email_placeholder")}
        type='text'
        name='email'
        value={inputValue}
        disabled={isPending}
        autoFocus
        onChange={e => {
          setInputValue(e.currentTarget.value);
          setErrorMsg("");
        }}
        onBlur={() => {
          setErrorMsg(
            `${inputValue.trim() !== "" && !isEmailValid ? "Please Enter a valid email" : ""}`
          );
        }}
        errorMsg={errorMsg}
      />

      <Button variant='green' type='submit' disabled={!isEmailValid || isPending}>
        {t("next_button")}
      </Button>
    </MainForm>
  );
}
