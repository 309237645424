import ChallengeItem from "@/Components/ChallengeItem/ChallengeItem";
import flagOrange from "@/assets/images/challenge-active@3x.png";
import flag from "@/assets/images/challenge@3x.png";
import explainerIcon from "@/assets/images/explainer-icon@3x.png";
import type { ChapterData } from "@/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classes from "./ChapterCard.module.css";

export default function ChapterCard({ chapterData }: { chapterData: ChapterData }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div className={classes.cardWrapper}>
      <Link to={`/${language}/courses/687175350918/chapters/${chapterData.chapter_id}/`}>
        <h3 className={classes.cardTitle}>{chapterData.chapter_title}</h3>
        <p className={classes.cardText}>{chapterData.chapter_description}</p>
      </Link>
      <ul className={classes.challengesList}>
        <ChallengeItem
          link={`/${language}/courses/687175350918/chapters/${chapterData.chapter_id}/explainer/`}
          iconUrl={explainerIcon}
          text={chapterData.explainer.explainer_title}
        />
        {chapterData.challenges.slice(0, 3).map(challenge => (
          <ChallengeItem
            link={`/${language}/courses/687175350918/chapters/${chapterData.chapter_id}/challenge/${challenge.challenge_id}/`}
            iconUrl={challenge.challenge_taken ? flagOrange : flag}
            text={challenge.challenge_title}
            key={challenge.challenge_id}
          />
        ))}
        {chapterData.challenges.length > 3 && (
          <li>
            <Link
              to={`/${language}/courses/687175350918/chapters/${chapterData.chapter_id}/`}
              className={classes.cardLink}
            >
              {t("see_all_details_label")}
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}
