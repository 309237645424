import { create } from "zustand";
import { persist } from "zustand/middleware";

type UserActionsState = {
  isCodeRun: boolean;
  waitedResponseLang: "en" | "ar" | null;
  redirectPath: string | null;
};

type UserActionsActions = {
  setIsCodeRun: (isRun: boolean) => void;
  setWaitedResponseLang: (waitedLang: "en" | "ar" | null) => void;
  setRedirectPath: (path: string | null) => void;
};

type UserActionsStore = UserActionsState & UserActionsActions;

const useUserActionsStore = create<UserActionsStore>()(
  persist(
    set => ({
      isCodeRun: false,
      waitedResponseLang: null,
      redirectPath: null,
      setIsCodeRun: isRun => set({ isCodeRun: isRun }),
      setWaitedResponseLang: waitedLang => set({ waitedResponseLang: waitedLang }),
      setRedirectPath: path => set({ redirectPath: path }),
    }),
    {
      name: "user-actions",
    }
  )
);

export default useUserActionsStore;
