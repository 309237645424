import Logo from "@/assets/images/CodeBites_Logo_Symbol.svg?react";
import AuthOptions from "@/Components/AuthOptions/AuthOptions";
import EmailForm from "@/Components/EmailForm/EmailForm";
import OtpForm from "@/Components/OtpForm/OtpForm";
import useUserStore from "@/store/UserStore";
import { ERROR_CODES, type OtpError, type UserData } from "@/types";
import { sendEmail, sendName, verifyOtp } from "@/utils/DataFetchers";
import { useMutation } from "@tanstack/react-query";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { z } from "zod";
import UserNameForm from "./UserNameForm/UserNameForm";

import classes from "./AuthPage.module.css";

const emailSchema = z.string().email({
  message: "Entered email is incorrect please provide valid email address",
});
const nameSchema = z.string().min(2).max(50);

export default function AuthPage() {
  const [authStep, setAuthStep] = useState(0);
  const [otpRemainingDuration, setOtpRemainingDuration] = useState(0);
  const [otpTimers, setOtpTimers] = useState({ duration: 0, resendingDuration: 0 });
  const [emailInputValue, setEmailInputValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState({
    firstNameError: "",
    lastNameError: "",
  });
  const [otpInputValue, setOtpInputValue] = useState("");
  const [firstNameInputValue, setFirstNameInputValue] = useState("");
  const [lastNameInputValue, setLastNameInputValue] = useState("");

  const { setUser } = useUserStore(state => state);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { mutate: emailMutation, isPending: emailIsPending } = useMutation({
    mutationFn: sendEmail,
    onSuccess: response => {
      if (!response.data.data) throw Error("No response data found");
      const { duration, resendingDuration } = response.data.data;
      setOtpRemainingDuration(duration);
      setOtpTimers({
        duration,
        resendingDuration,
      });

      // MAKE SURE THE USER AT EMAIL FORM TO NAVIGATE HIM TO THE OTP STEP AFTER SUCCESSFUL EMAIL SUBMISSION
      if (authStep === 1) {
        setAuthStep(prev => ++prev);
      }
    },
    onError: (error: OtpError | Error) => {
      // IF THE ERROR IS OF TYPE RESPONSE ERROR THEN SHOW THE RESPONSE ERROR MSG ELSE SHOW THE NORMAL ERROR MSG
      if (!("data" in error)) {
        setErrorMsg(error.message);
        return;
      }
      if ("data" in error && error.data.errorCode === ERROR_CODES.InvalidEmail) {
        setErrorMsg(error.data.errorMessage);
      }

      if (error.data.errorCode === ERROR_CODES.OtpResendingError && authStep === 1) {
        setAuthStep(prev => ++prev);
      }
      setOtpRemainingDuration(error.data.data.duration);
      setOtpTimers({
        duration: error.data.data.duration,
        resendingDuration: error.data.data.resendingDuration,
      });
    },
  });

  const { mutate: otpMutation, isPending: otpIsPending } = useMutation({
    mutationFn: verifyOtp,
    onSuccess: ({
      token_scope,
      user_id,
      user_first_name,
      user_last_name,
      user_email,
    }: UserData) => {
      if (token_scope === "existing_account") {
        setUser({
          userId: user_id,
          userEmail: user_email,
          userFirstName: user_first_name,
          userLastName: user_last_name,
          isAuthenticated: true,
        });

        return;
      }
      setAuthStep(prev => ++prev);
    },
    onError: (error: OtpError | Error) => {
      if (!("data" in error)) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(`${t("invalid_verification_code_message")}`);
      }
    },
  });

  const { mutate: nameMutation, isPending: nameIsPending } = useMutation({
    mutationFn: sendName,
    onSuccess: ({ user_id, user_first_name, user_last_name, user_email }: UserData) => {
      setUser({
        userId: user_id,
        userEmail: user_email,
        userFirstName: user_first_name,
        userLastName: user_last_name,
        isAuthenticated: true,
      });
    },
    onError: (error: Error) => {
      setErrorMsg(error.message);
    },
  });

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;
    if (otpRemainingDuration > 0) {
      timeOutId = setTimeout(() => {
        setOtpRemainingDuration(prev => prev - 1);
      }, 1000);
    }
    return () => clearTimeout(timeOutId);
  }, [otpRemainingDuration]);

  // PARSE EMAIL INPUT VALUE
  const parsedEmail = emailSchema.safeParse(emailInputValue);
  // PARSE FIRST NAME INPUT VALUE
  const parsedFirstName = nameSchema.safeParse(firstNameInputValue);
  // PARSE LAST NAME INPUT VALUE
  const parsedLastName = nameSchema.safeParse(lastNameInputValue);

  const emailSubmitHandler = (e: React.FormEvent<HTMLFormElement> | MouseEvent) => {
    e.preventDefault();
    setErrorMsg("");
    if (parsedEmail.success) {
      emailMutation({ user_email: emailInputValue });
    }
  };

  const otpSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMsg("");
    otpMutation({ otp: otpInputValue, user_email: emailInputValue });
  };

  const userInfoSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMsg("");
    if (parsedFirstName.success && parsedLastName.success) {
      nameMutation({
        user_email: emailInputValue,
        user_first_name: firstNameInputValue,
        user_last_name: lastNameInputValue,
      });
    }
  };

  return (
    <section className={`container-45 ${classes.authSection}`}>
      <Link to={`/${language}/`}>
        <Logo className={classes.logo} />
      </Link>
      {[
        <AuthOptions key={"authOptions"} setStep={setAuthStep} />,
        <EmailForm
          key={"email"}
          inputValue={emailInputValue}
          errorMsg={errorMsg}
          isEmailValid={parsedEmail.success}
          isPending={emailIsPending}
          setInputValue={setEmailInputValue}
          setErrorMsg={setErrorMsg}
          submitHandler={emailSubmitHandler}
        />,
        <OtpForm
          key={"otp"}
          otpValue={otpInputValue}
          isPending={otpIsPending}
          remainingDuration={otpRemainingDuration}
          otpTimers={otpTimers}
          resendingPending={emailIsPending}
          otpErrorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          otpSubmitHandler={otpSubmitHandler}
          setOtpValue={setOtpInputValue}
          resendHandler={emailSubmitHandler}
        />,
        <UserNameForm
          key={"userName"}
          firstNameValue={firstNameInputValue}
          lastNameValue={lastNameInputValue}
          isFirstNameValid={parsedFirstName.success}
          isLastNameValid={parsedLastName.success}
          isPending={nameIsPending}
          nameErrorMsg={nameErrorMsg}
          setFirstNameValue={setFirstNameInputValue}
          setLastNameValue={setLastNameInputValue}
          submitHandler={userInfoSubmitHandler}
          setNameErrorMsg={setNameErrorMsg}
        />,
      ].map((component, index) => index === authStep && component)}
    </section>
  );
}
