import ProfileIcon from "@/assets/images/profile-link.svg?react";
import SignOutIcon from "@/assets/images/right-from-bracket-solid.svg?react";
import UserIcon from "@/assets/images/user-solid.svg?react";
import useUserStore from "@/store/UserStore";
import type { ResponseError } from "@/types";
import { logOut } from "@/utils/DataFetchers";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import DropDownMenuWrapper from "../DropDownMenuWrapper/DropDownMenuWrapper";

import classes from "./UserDetails.module.css";

export default function UserDetails({
  userFirstName,
  userLastName,
}: {
  userFirstName: string;
  userLastName: string;
}) {
  const { setUser } = useUserStore(state => state);

  const navigate = useNavigate();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { mutate, isPending } = useMutation({
    mutationFn: logOut,
    onSuccess: () => {
      setUser(null);
      navigate(`/${language}/`);
    },
    onError: (error: ResponseError) => {
      console.log(error.data.errorMessage);
    },
  });

  const signOutHandler = async () => {
    mutate();
  };

  return (
    <DropDownMenuWrapper
      menuWrapperClassName={classes.userContainer}
      menuBtnClassName={classes.userBtn}
      menuBtnContent={
        <>
          <UserIcon />
          <p>{`${userFirstName} ${userLastName}`}</p>
          <span className='chevron down'></span>
        </>
      }
    >
      <ul className={classes.userMenu}>
        <li>
          <Link to={`/${language}/myaccount/`} className={classes.userMenuBtn}>
            {t("user_profile_label")} <ProfileIcon />
          </Link>
        </li>
        <li>
          <button
            className={classes.userMenuBtn}
            type='button'
            onClick={signOutHandler}
            disabled={isPending}
          >
            {t("sign_out_label")} <SignOutIcon />
          </button>
        </li>
      </ul>
    </DropDownMenuWrapper>
  );
}
