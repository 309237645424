import Logo from "@/assets/images/CodeBites_Logo_Symbol.svg?react";
import Error404 from "@/assets/images/illustration.svg?react";
import LinkBtn from "@/Components/LinkBtn/LinkBtn";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classes from "./ErrorPage.module.css";

export default function ErrorPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <main className={classes.errorPage}>
      <section className={`${classes.errorSection} container-125`}>
        <Link to={`/${language}/`}>
          <Logo className={classes.logo} />
        </Link>
        <Error404 className={classes.errorLogo} />
        <p>{t("page_not_found_error_message")}</p>
        <LinkBtn variant='signUp' to={`/${language}/`}>
          Go Home
        </LinkBtn>
      </section>
    </main>
  );
}
