import { LANGUAGE, PAYMENT_CLIENT_ID } from "@/utils/Constants";

export enum ERROR_CODES {
  InvalidEmail = "0016",
  OtpResendingError = "0018",
  InvalidOtp = "0007",
}

export type Language = (typeof LANGUAGE)[number];

export interface UserData {
  token_scope?: string;
  user_id: number;
  user_email: string;
  user_first_name: string;
  user_last_name: string;
  subscription_details?: {
    start_date: string;
    end_date: string;
  };
}

export interface ResponseError extends Error {
  message: string;
  data: {
    hasError: boolean;
    errorMessage: string;
    errorCode: string;
  };
}

export interface OtpError extends ResponseError {
  data: {
    hasError: boolean;
    errorMessage: string;
    errorCode: string;
    data: {
      resendingDuration: number;
      duration: number;
    };
  };
}

export interface CourseData {
  course_id: string;
  course_title: string;
  chapters: ChapterData[];
}

export interface ChapterData {
  chapter_id: string;
  chapter_title: string;
  chapter_description: string;
  explainer: {
    explainer_id: string;
    explainer_title: string;
  };
  challenges: ChallengeData[];
}
export interface ChallengeData {
  challenge_id: number;
  challenge_title: string;
  challenge_description: string;
  user_message: string;
  help_messages: HelpMessages;
  system_message: string;
  challenge_head: string;
  preloaded_code: string;
  challenge_taken: boolean;
}

export interface ExplainerData {
  id: number;
  title: string;
  content: string;
  video_url?: string;
  is_active: boolean;
}

export type Selection =
  | {
      selectionStartLineNumber: number;
      selectionStartColumn: number;
      positionLineNumber: number;
      positionColumn: number;
    }
  | undefined;

export enum Theme {
  Dark = "dark",
  Light = "light",
}

export interface CodePayload {
  initialCode?: string;
  code: string;
}

export interface HelpMessages {
  arabic_help_message: string;
  case_1a: string;
  case_1b: string;
  case_2a: string;
  case_2b: string;
  case_3a: string;
  case_3b: string;
}

export type RunCodeRequest = {
  courseId: string;
  chapterId: string;
  challengeId: string;
  code: string | undefined;
  output?: string;
  errorMessage?: string;
};

export type AITextToAudioRequest = {
  chatCompletionChunk: string;
  courseId: string;
  chapterId: string;
  challengeId: string;
};

export type PaymentRequestBody = {
  amount: number;
  clientId: typeof PAYMENT_CLIENT_ID;
  language: Language;
} & (
  | {
      type: "fawry";
      mobile: string;
    }
  | {
      type: "paymob";
      successReturnUrl: string;
      failureReturnUrl: string;
    }
);

export type FawryResponseBody = {
  referenceNumber: string;
  amount: number;
};
export type PaymobResponseBody = {
  paymentCode: number;
};
