import FawryDetails from "@/Components/FawryDetails/FawryDetails";
import { FawryResponseBody } from "@/types";
import { useMutationState } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";

export default function FawryPaymentDetailsPage() {
  const { state } = useLocation();

  const {
    i18n: { language },
  } = useTranslation();

  const data = useMutationState({
    // this mutation key needs to match the mutation key of the given mutation
    filters: { mutationKey: ["fawryMutation"] },
    select: mutation => mutation.state.data as FawryResponseBody,
  });

  if (state !== "fawryDetails") {
    return <Navigate to={`/${language}/payment`} />;
  }

  const latestData = data[data.length - 1];

  if (!latestData) {
    throw Error("No fawry data found");
  }

  return (
    <section className='container-50 form-wrapper'>
      <FawryDetails fawryResponse={latestData} />
    </section>
  );
}
