import PrivacyPolicyIcon from "@/assets/images/privacy-policy.svg?react";

import classes from "@/Components/UserPrivacy/PrivacyPageContent.module.css";

export default function TermsPageEnContent() {
  return (
    <section className={`container-80 ${classes.privacySection}`}>
      <h1 className={classes.privacyHeader}>
        <PrivacyPolicyIcon />
        CodeBites Terms and Conditions
      </h1>
      <div className={classes.privacyContainer}>
        <h2>1. About Us</h2>
        <p>
          CodeBites is a product provided by Nagwa Limited (hereinafter Nagwa). These products and
          services shall be referred to as our “Services” for the rest of these terms and
          conditions. We will use CodeBites to refer to this Nagwa product for the rest of this
          policy.
        </p>
        <p>
          Nagwa Limited is a company registered in England and Wales with company number 10069565,
          and with registered address York House, 41 Sheet Street, Windsor, Berkshire, United
          Kingdom, SL4 1DD.
        </p>

        <h2>2. Definitions</h2>
        <p>
          2.1 Any mention of “CodeBites” or “we” throughout this Agreement is in reference to
          CodeBites as described above.:
        </p>
        <p>
          2.2 Our “Services” are defined as any and all services and products offered by CodeBites
          including (but not limited to) content and technology services.
        </p>
        <p>
          2.3 Any mention of “you” or “your” in this document refers to any person accessing or
          using the Services, or any other person with authorized access to the Services.
        </p>
        <p>
          2.4 A “User” is defined in this document as any person authorized to use any of the
          Services.
        </p>

        <p>
          2.5 “User Data” refers to the personal data processed by CodeBites as a processor or
          sub-processor for, and on behalf of, the Organization for which you have purchased access
          to the Services, or the personal data processed by CodeBites as a data controller if you
          are an individual customer purchasing access for yourself or a child for whom you have
          parental responsibility.
        </p>

        <p>
          2.6 “Data Protection Laws” (including implementing and supplemental legislation) refers to
          the UK Data Protection Act 2018 and the UK GDPR.
        </p>
        <p>
          2.7 “Security Incident” refers to a security breach resulting in the accidental or
          unlawful destruction, loss, modification, unauthorized disclosure of or access to User
          Data.
        </p>
        <p>
          2.8 The terms “controller”, “personal data”, “processor”, “sub-processor”, “data subject”,
          and “processing” have the meanings defined by the Data Protection Laws.
        </p>
        <h2>3. Your Agreement With CodeBites</h2>
        <p>
          3.1 There exists a legally binding agreement (“Agreement”) as outlined in this document
          between a User and CodeBites that begins when the User first purchases access to any of
          the Services, that the User must comply with. By using any of our Services, the User
          agrees to the terms and conditions outlined in this document.
        </p>
        <p>
          3.2 The Privacy Policy, that you and each of your Users must comply with, and that governs
          our use of any data belonging to any User can be found
          <a href='https://www.codebites.com/en/privacy/.'>here</a>
        </p>
        <p>
          3.3 The Agreement will apply every time you purchase access to any of the Services. No
          terms or conditions contained or referred to in any correspondence, order, or
          documentation submitted by you elsewhere or implied in any way will apply.
        </p>
        <h2>4. Access to CodeBites Services</h2>
        <p>
          4.1 You are responsible for providing and maintaining all software, hardware, network
          availability, and internet connectivity necessary to use the Services.
        </p>

        <h2>5. Your Use of CodeBites Services</h2>
        <p>
          5.1 If you believe there has been any security breach or other unauthorized use of the
          Services, you must immediately inform CodeBites by emailing{" "}
          <a href='nagwa@nagwa.com.'> nagwa@nagwa.com.</a>
        </p>
        <p>
          5.2 If we have reasonable grounds to suspect that the Services are being used by you in
          any way that is not permitted in the Agreement, we may suspend and terminate your access
          to the Services and block access to all other Services. This will not affect any legal
          rights we may also have under this Agreement. If such an event occurs, we are not
          obligated to refund any fees you may have paid to us.
        </p>
        <h2>6. Intellectual Property Rights</h2>
        <p>
          6.1 Upon receiving your payment of the fees corresponding to the Service(s) you purchase,
          CodeBites will grant you a non-exclusive non-transferable license for the term of your
          Agreement (subject to Clause 6.3 below) to retrieve and display materials delivered as
          part of the Services (“Content”) on a digital device such as a smartphone or computer
          screen or print individual pages on paper and store these pages electronically for
          educational non-commercial purposes only. This license will allow access by only you.
        </p>
        <p>
          6.2 A User may use any Content that has been purchased for the User within the premises of
          the Organization and the User’s home either on individual electronic devices for internal
          educational non-commercial purposes only.
        </p>
        <p>6.3 Except where permitted by any applicable law, Users must not:</p>
        <ul>
          <li>
            Commercially exploit or take advantage in any way of any or all parts of the Services,
          </li>
          <li>
            Use the whole or any part of the Services and Content in any manner except as outlined
            in Clause 6.2,
          </li>
          <li>Download the whole or any part of the Content except as allowed by the Services,</li>
          <li>
            Modify, copy, reproduce, the whole or any part of the Services and Content, except as
            explicitly allowed by the Services,
          </li>
          <li>
            Disassemble, decompile, or reverse engineer the whole or any part of the Services,
          </li>
          <li>Redistribute any Content,</li>
          <li>Make the Content available to a third party in a way,</li>
          <li>Remove any trademark, copyright, or other notice on any of the Services,</li>
          <li>Use the website to send any chain emails, spam, or junk,</li>
          <li>Disrupt or interfere with the CodeBites website or any Services linked to it,</li>
          <li>
            Introduce any viruses or any other properties into the CodeBites website or any Services
            linked to it,
          </li>
          <li>Reveal their password to anyone else,</li>
          <li>Allow someone else to use their login information.</li>
        </ul>

        <p>
          Each User is responsible for their own login information and any use of this login
          information in any manner.
        </p>
        <p>
          6.4 You acknowledge that any logos, brands, and product names used in the Services are
          trademarks and that you may not use them for any purpose.
        </p>
        <h2>7. Fees</h2>
        <p>
          7.1 You agree to pay us the fees (“Fees”) for the Service(s) you purchase, as outlined on
          the purchase pages for the Service(s) or in our invoice to you. You may choose to pay
          using a credit card, e-wallet, or cash aggregator. You may pay using other methods
          provided you have explicit prior written agreement from CodeBites.
        </p>
        <p>
          7.2 CodeBites will only make the Services available to you once your payment has been
          received (or if you are using a credit card, your payment has been authorized).
        </p>
        <p>
          7.3 If you are late with your payments to us, we may remove your access to all or part of
          the Services, as well as the access of your Users to all or part of the Services. We may
          also terminate any of your subscriptions with us.
        </p>
        <p>
          7.4 We expressly reserve the right to increase any and all Fees from time to time with
          regards to any monthly subscriptions.
        </p>
        <p>
          7.5 Any right of deduction, withholding, or set off is hereby expressly excluded and all
          sums due from you shall be paid by you to us free from any deductions, withholdings, or
          set off of any kind.
        </p>

        <h2>8. Confidentiality</h2>
        <p>
          8.1 In the process of providing you with our Services, we will need to obtain confidential
          information from you, and provide you with confidential information. You agree to keep all
          such commercial, financial, and other confidential information obtained from us
          confidential. We also agree to keep all confidential information obtained from you
          confidential. Each party agrees to only reveal confidential information to those that need
          to know it in order to fulfill the terms of this Agreement. Each party shall use all
          reasonable endeavors to ensure that they comply with these restrictions.
        </p>
        <h2>9. Warranties</h2>
        <p>
          9.1 We warrant that we have full right and title to enter into the Agreement, and we will
          use reasonable skill and care in the performance of our obligations under your Agreement.
        </p>
        <p>
          9.2 We do not give you any other warranties. All other warranties, terms, or
          representations having equivalent effect that may be implied by law are excluded to the
          extent permitted by law, including any warranties as to satisfactory quality or fitness
          for a particular purpose.
        </p>
        <p>
          9.3 No representative of CodeBites is authorized to give or make any other representation,
          warranty, condition, or term. No representative of CodeBites can modify the warranties
          given in clause 9 in any way.
        </p>
        <h2>10. Limitation of Liability</h2>
        <p>
          10.1 CodeBites shall not be liable for any loss of data, or loss of profit whether caused
          by direct or indirect damage arising from the Agreement.
        </p>
        <p>
          10.2 CodeBites shall not be liable for any special, indirect, consequential or incidental
          damages (including damages for loss of use) arising from the Agreement, in any way caused
          by your use of (or inability to use) the Services, or from any action taken (or lack
          thereof) as a result of using the Services.
        </p>

        <p>
          10.3 You acknowledge that we have no liability for any loss or damage suffered by you, or
          claim made against you as a result of any unauthorized access to the Services by breach by
          any of you or other parties of the terms of your Agreement.
        </p>
        <p>
          10.4 In the event and except for the circumstances outlined in clause 10.5, our liability
          to you in respect of any claim for breach of Agreement, negligence, or otherwise in
          relation to the Services shall be limited to the total Fees paid or payable to CodeBites
          by you during the 12 months preceding the cause of action.
        </p>
        <p>
          10.5 Nothing will limit either party’s liability for death or personal injury caused by
          its negligence, or for fraud, fraudulent misrepresentation, or for any other liability
          that cannot be excluded or limited under applicable law. Clauses 10.1 through 10.4 shall
          not apply in these circumstances.
        </p>
        <p>
          10.6 You must promptly inform CodeBites in the event of any claim by a third party
          received by you in relation to the Services and you must comply with CodeBites reasonable
          requests in relation to such claim.
        </p>

        <h2>11. Termination and Cancellation</h2>

        <p>
          11.1 Your Agreement, and any subscriptions, commence on the day that we accept your order
          for our Services, and continue from that date for a period indicated on the Service(s)
          purchased by you. You will receive confirmation of your order in the form of access to our
          Services. The Agreement will automatically be terminated at the end of the subscription
          period.
        </p>

        <p>
          11.3 Either party (you or us) can terminate this Agreement by notifying the other in
          writing if the other materially breaches any of the terms of this Agreement and fails to
          rectify such a breach (if capable of rectifying) within 14 calendar days of the notice of
          the breach.
        </p>

        <p>
          11.4 We may terminate the Agreement immediately if we believe that there has been, or
          there is a breach of security, or if you fail to comply with the terms of the Agreement.
        </p>

        <p>
          11.5 We may terminate your Agreement upon giving you 30 calendar days’ notice (or less
          where we have a business requirement) if we discontinue the Services. In such a scenario
          we may offer you a pro rata refund for the remainder of any subscription period.
        </p>
        <p>
          11.6 Upon expiration or termination of the Agreement, CodeBites will retain your
          Organization’s User Data for 30 calendar days, after which we may delete some or all of
          this data.
        </p>

        <p>
          11.7 Upon expiration or termination of the Agreement, your license to use, and access to,
          the Services will terminate. You must delete any Content that you may have downloaded from
          all servers or devices it may have been downloaded to. The provisions of clauses 6, 8, 9,
          10, 11, and 13 will survive beyond any termination or expiration of your Agreement.
        </p>
        <h2>12. Changes and Updates</h2>
        <p>
          12.1 We reserve the right, at our sole discretion, to make changes to any parts or the
          whole of the Services, provided that the changes do not materially reduce the Content or
          the functionality of the relevant parts of our Services beyond a specified “outage time”,
          of which CodeBites will inform you in advance.
        </p>
        <p>
          12.2 If we replace any of the Services you have purchased access to with a different
          service or product during the time that your Agreement is in effect, we will give you at
          least 10 calendar days’ notice and allow you to terminate the Agreement. If you choose not
          to terminate, we may migrate you to the replacement product or service for the remainder
          of your term without additional charge.
        </p>
        <p>
          12.3 CodeBites reserves the right to vary the terms of your Agreement on 30 calendar days’
          notice. Upon expiration of the period stated in the notice, the varied terms shall apply.
          You may terminate the updated agreement on a further 30 calendar days’ notice.
        </p>

        <h2>13. General and Miscellaneous</h2>
        <p>
          13.1 Neither party will be liable for any failure or delay in performing their respective
          obligations under this Agreement to the extent that the failure or delay is the result of
          any cause or circumstance beyond either party’s reasonable control including but not
          limited to fire, war, riot, crime, epidemic, act of God, power outage, internet failure,
          security breach, and “Force Majeure”. We may terminate the Agreement and discontinue any
          Services in the event of a Force Majeure.
        </p>
        <p>
          13.2 You may not assign or transfer your rights or obligations under your Agreement
          without our prior express written consent. We may at any time assign or transfer our
          rights and obligations under your Agreement to any third party.
        </p>

        <p>
          13.3 This Agreement (including the Privacy Policy) represents the entire agreement between
          you and CodeBites in relation to its subject matter. You acknowledge that you have not
          relied upon any statement or representation not recorded in this Agreement inducing you to
          enter into it, but this does not exclude the liability of either party for any
          pre-contractual statements or representations made fraudulently.
        </p>
        <p>
          13.4 If any provision of this Agreement is found to be invalid by any court having
          competent jurisdiction, the invalidity of that provision will not affect the validity of
          the remaining provisions of this Agreement which shall remain in full force and effect.
        </p>
        <p>
          13.5 Failure by either you or CodeBites to exercise any right or remedy under this
          Agreement does not constitute a waiver of that right or remedy.
        </p>

        <p>
          13.6 The relationship between the parties established in the Agreement is that of
          independent contractors, and not employment, partnership franchise, joint venture, or any
          other such relationship. Each of the parties shall conduct its respective business at its
          own initiative, responsibility, and expense, and shall have no authority to incur any
          obligations on behalf of the other party to the Agreement.
        </p>
        <p>
          13.7 Any notice to be given under this Agreement to either party may be served by the
          other party either by first class post to such party at the address set out in the
          Agreement, or by email to such address as that party may notify from time to time. Any
          notice served by post shall be deemed to have been served on the first working day
          following the date of posting. Any notice sent by email shall be deemed to have been duly
          sent on the date of transmission if a confirmation of receipt has been received.
        </p>
        <p>
          13.8 Any person who is not a party to your Agreement shall have no rights under the
          Agreements (Rights of Third Parties) Act 1999 to enforce any of its terms.
        </p>
        <p>
          13.9 Your Agreement will be governed by and construed in accordance with English law
          without regard to its conflict of laws. You agree that the courts of England shall have
          exclusive jurisdiction to settle any dispute which may arise out of, under, or in
          connection with your Agreement or the legal relationship established by it, and for those
          purposes irrevocably submit all disputes to the jurisdiction of the English courts.
          However, CodeBites may also begin an action against you in the courts of your place of
          residence.
        </p>
        <p>
          13.10 Any reference to any legislative provision shall be deemed to include any subsequent
          re-enactment or amending provision.
        </p>
      </div>
    </section>
  );
}
