import MyData from "@/assets/images/my-data.svg?react";
import { Link } from "react-router-dom";

import classes from "./MyDataPage.module.css";

export default function MyDataPage() {
  return (
    <section className={`container-80 ${classes.dataSection}`}>
      <h1 className={classes.dataHeader}>
        <MyData />
        Delete account instructions.
      </h1>
      <div className={classes.dataContainer}>
        <h2>You can delete your CodeBites account by following the steps below:</h2>
        <ul>
          <li>
            Sign in at <Link to='https://www.codebites.com/'>https://www.codebites.com/</Link>.
          </li>
          <li>Click on your name, which is displayed on the top navigation bar.</li>
          <li>Click on “My Account” in the drop-down menu.</li>
          <li>Click on “Delete Account”.</li>
          <li>
            You will be alerted that clicking “Confirm” will delete your account and all personal
            data associated with it.
          </li>
          <li>Click “Confirm” to complete deletion.</li>
        </ul>
      </div>
    </section>
  );
}
