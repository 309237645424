import DropDownMenuWrapper from "@/Components/DropDownMenuWrapper/DropDownMenuWrapper";
import HeaderAuthBtnsContainer from "@/Components/HeaderAuthBtnsContainer/HeaderAuthBtnsContainer";
import LanguageBtn from "@/Components/LanguageBtn/LanguageBtn";
import UserDetails from "@/Components/UserDetails/UserDetails";
import Logo from "@/assets/images/CodeBites_Logo.svg?react";
import CopyIcon from "@/assets/images/copy.svg?react";
import DarkIcon from "@/assets/images/dark.svg?react";
import DownLoadIcon from "@/assets/images/download.svg?react";
import LightIcon from "@/assets/images/light.svg?react";
import useCodeStore from "@/store/CodeStore";
import { useThemeStore } from "@/store/ThemeStore";
import useUserStore from "@/store/UserStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import classes from "./ChallengeHeader.module.css";

export default function ChallengeHeader({ title }: { title: string }) {
  const { user } = useUserStore(state => state);
  const { theme, setTheme } = useThemeStore(state => state);
  const { code } = useCodeStore(state => state);

  const [showCopiedPopup, setShowCopiedPopup] = useState(false);

  const { challenge_id } = useParams();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const copyCode = async () => {
    if (!challenge_id) return;
    try {
      await navigator.clipboard.writeText(code[challenge_id] || "");
      setShowCopiedPopup(true);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const downloadCode = () => {
    if (!challenge_id) return;
    const fileData = new Blob([code[challenge_id] || ""], { type: "text/plain" });
    const fileUrl = URL.createObjectURL(fileData);
    const downloadLink = document.createElement("a");

    downloadLink.href = fileUrl;
    downloadLink.download = `${title ?? "script"}.py`;
    downloadLink.click();

    URL.revokeObjectURL(fileUrl);
  };

  const renderChallengeActionsMenu = () => (
    <ul className={classes.actionsBtnsWrapper}>
      <li>
        <button onClick={downloadCode} type='button'>
          <p>
            <DownLoadIcon />
            {t("download_code_button")}
          </p>
        </button>
      </li>
      <li>
        <button onClick={copyCode} type='button'>
          <p>
            <CopyIcon />
            {t("copy_code_button")}
          </p>
        </button>
      </li>
      <li>
        <button
          type='button'
          onClick={() => {
            if (theme === "dark") {
              setTheme("light");
            } else {
              setTheme("dark");
            }
          }}
        >
          <p>
            {theme === "dark" ? (
              <>
                <LightIcon />
                {t("light_mode_label")}
              </>
            ) : (
              <>
                <DarkIcon />
                {t("dark_mode_label")}
              </>
            )}
          </p>
        </button>
      </li>
      <li>
        <LanguageBtn />
      </li>
    </ul>
  );

  return (
    <header className={`${classes.challengeHeader} ${theme}`}>
      <div
        className={`${classes.popup} ${showCopiedPopup ? classes["fade-out"] : ""}`}
        onAnimationEnd={e => {
          e.animationName.includes("fadeOut") && setShowCopiedPopup(false);
        }}
      >
        <p>{t("code_copied_message")}</p>
      </div>

      <div className={classes.navWrapper}>
        <Link to={`../../`} relative='path'>
          <span className='chevron left'></span>
        </Link>

        <Link to={`/${language}/`}>
          <Logo />
        </Link>
      </div>
      <div className={classes.contentWrapper}>
        {user?.isAuthenticated ? (
          <UserDetails userFirstName={user.userFirstName} userLastName={user.userLastName} />
        ) : (
          <HeaderAuthBtnsContainer />
        )}

        <div className={classes.dropDown}>
          <DropDownMenuWrapper
            menuWrapperClassName={classes.challengeOptions}
            menuBtnClassName={classes.actionsMenuBtn}
            menuBtnContent={<span className='chevron down'></span>}
            menuBtnTitle='challenge actions drop menu'
          >
            {renderChallengeActionsMenu()}
          </DropDownMenuWrapper>
        </div>
        <div className={classes.horizontalMenu}>
          <div className={classes.challengeOptions}>{renderChallengeActionsMenu()}</div>
        </div>
      </div>
    </header>
  );
}
