import useUserActionsStore from "@/store/UserActionsStore";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LinkBtn from "../LinkBtn/LinkBtn";

import classes from "./HeaderAuthBtnsContainer.module.css";

export default function HeaderAuthBtnsContainer() {
  const { setRedirectPath } = useUserActionsStore(state => state);

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { pathname } = useLocation();
  return (
    <ul className={classes.userAuthList}>
      <li>
        <LinkBtn
          variant='signIn'
          to={`/${language}/user/signIn/`}
          className={classes.login}
          onClick={setRedirectPath.bind(null, pathname)}
        >
          {t("sign_in_label")}
        </LinkBtn>
      </li>
      <li>
        <LinkBtn
          variant='signUp'
          to={`/${language}/user/signUp/`}
          className={classes.register}
          onClick={setRedirectPath.bind(null, pathname)}
        >
          {t("sign_up_label")}
        </LinkBtn>
      </li>
    </ul>
  );
}
