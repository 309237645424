import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import HttpApi from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

import * as ar from "@/assets/locales/ar.json";
import * as en from "@/assets/locales/en.json";
import { ROUTES } from "./utils/Network";

const options = {
  // Order for auto detecting language
  order: ["navigator", "path", "htmlTag"],
  // keys or params to lookup language from
  lookupFromPathIndex: 0,

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

i18n
  .use(LanguageDetector)
  // use the backend chained plugin to fetch the localization sheet
  .use(Backend)
  // pass the react i18n  instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    detection: options,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      // init backend plugins to use
      backends: [
        HttpApi,
        resourcesToBackend({
          en: {
            translation: {
              ...en,
            },
          },
          ar: {
            translation: {
              ...ar,
            },
          },
        }),
      ],
      backendOptions: [
        {
          // fetch the localization from the backend
          // Make loadPath as a function to fix safari returning lngs as ar-EG - en-GP
          loadPath: (lngs: string[]) => {
            return ROUTES.localization.url(`${lngs[0]?.includes("ar") ? "ar" : "en"}`);
          },
        },
      ],
      // allow credentials on cross domain requests
      withCredentials: false,
    },
  });

export default i18n;
