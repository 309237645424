import useUserStore from "@/store/UserStore";
import type { UserData } from "@/types";
import { useEffect } from "react";
import { Outlet, useLoaderData } from "react-router-dom";

export default function AuthWrapper() {
  const loaderData = useLoaderData() as UserData | null;
  const { setUser } = useUserStore(state => state);

  useEffect(() => {
    if (loaderData) {
      const { user_id, user_email, user_first_name, user_last_name, subscription_details } =
        loaderData;
      setUser({
        userId: user_id,
        userEmail: user_email,
        userFirstName: user_first_name,
        userLastName: user_last_name,
        isAuthenticated: true,
        subscriptionDetails: subscription_details,
      });
    }
  }, [loaderData, setUser]);

  return <Outlet />;
}
