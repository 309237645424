import type { UserData } from "@/types";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  Outlet,
  useLocation,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";

export default function ProtectedRoutes() {
  const userData = useRouteLoaderData("auth-wrapper") as UserData;
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams, _] = useSearchParams();
  if (!userData) return <Navigate to={`/${i18n.language}/user/signIn/`} replace={true} />;

  // CHECK IF THE USER ALREADY SUBSCRIBED THEN NAVIGATE HIM TO THE HOME PAGE
  if (
    pathname.includes("payment") &&
    userData.subscription_details &&
    !searchParams.has("success")
  ) {
    return <Navigate to={`/${i18n.language}/`} />;
  }

  return <>{userData?.user_id && <Outlet />}</>;
}
