import Logo from "@/assets/images/CodeBites_Logo.svg?react";
import HeaderAuthBtnsContainer from "@/Components/HeaderAuthBtnsContainer/HeaderAuthBtnsContainer";
import LanguageBtn from "@/Components/LanguageBtn/LanguageBtn";
import UserDetails from "@/Components/UserDetails/UserDetails";
import useUserStore from "@/store/UserStore";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import classes from "./MainHeader.module.css";

export default function MainHeader() {
  const { user } = useUserStore(state => state);
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <header className={classes.headerContainer}>
      <div className='container-125'>
        <nav className={classes.navContainer}>
          <Link to={`/${language}/`} className={classes.logo}>
            <Logo />
          </Link>
          {/* <ul className={classes.navLinksList}>
            <li>
              <NavLink to="#">Topics</NavLink>
            </li>
            <li>
              <NavLink to="#">How it works</NavLink>
            </li>
            <li>
              <NavLink to="#">About Us</NavLink>
            </li>
            <li>
              <NavLink to="#">Contact Us</NavLink>
            </li>
          </ul> */}
          <>
            {user?.isAuthenticated ? (
              <UserDetails userFirstName={user.userFirstName} userLastName={user.userLastName} />
            ) : (
              <HeaderAuthBtnsContainer />
            )}
          </>
          <LanguageBtn />
        </nav>
      </div>
    </header>
  );
}
