import { Language } from "@/types";
import { ReactNode } from "react";
import { createPortal } from "react-dom";

export const fetchData = async (
  url: string | URL | Request,

  init?: RequestInit | undefined
) => {
  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    ...init,
  });

  if (!res.ok) {
    throw await res.json();
  }
  //No await for the res.json() because it will be awaited implicitly from tanStack query.
  return res.json();
};

export const formateSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};

export const portalElement = (
  element: ReactNode,
  targetElement: Element | DocumentFragment | null
) => {
  if (!element || !targetElement || targetElement === null) return element;

  return createPortal(element, targetElement);
};

export const formatDateStr = (dateStr: string, lang: Language) => {
  if (!dateStr || !lang) throw Error("please provide all required parameters");
  return new Date(dateStr).toLocaleDateString(`${lang === "ar" ? "ar-EG" : "en-UK"}`, {
    numberingSystem: "latn",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
