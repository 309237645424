import CoursesSection from "@/Components/CoursesSection/CoursesSection";
import EmptyView from "@/Components/EmptyView/EmptyView";
import HeroSection from "@/Components/HeroSection/HeroSection";
import useUserActionsStore from "@/store/UserActionsStore";
import useUserStore from "@/store/UserStore";
import type { CourseData, ResponseError } from "@/types";
import { getCoursesData } from "@/utils/DataFetchers";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const { user } = useUserStore(state => state);
  const { setIsCodeRun, setRedirectPath, setWaitedResponseLang } = useUserActionsStore(
    state => state
  );

  const {
    i18n: { language },
  } = useTranslation();

  const {
    data: coursesData,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<CourseData[], ResponseError | Error>({
    queryKey: ["chapters", user?.isAuthenticated, language],
    queryFn: getCoursesData,
  });

  useEffect(() => {
    setIsCodeRun(false);
    setRedirectPath(null);
    setWaitedResponseLang(null);
  }, []);

  return (
    <>
      {isLoading || isFetching ? (
        <></>
      ) : (
        <>
          <HeroSection firstCourse={coursesData?.[0]} />
          {error || coursesData?.length === 0 || !coursesData ? (
            <EmptyView refetch={refetch} />
          ) : (
            <CoursesSection coursesData={coursesData} />
          )}
        </>
      )}
    </>
  );
}
