import { create } from "zustand";
import { persist } from "zustand/middleware";

export type ThemeState = {
  theme: "light" | "dark" | null;
};

export type ThemeActions = {
  setTheme: (theme: "light" | "dark" | null) => void;
};

export type ThemeStore = ThemeState & ThemeActions;

export const useThemeStore = create<ThemeStore>()(
  persist(
    set => ({
      theme: "light",
      setTheme: theme => set({ theme }),
    }),
    { name: "theme" }
  )
);
