import type { CourseData } from "@/types";
import ChapterCard from "../ChapterCard/ChapterCard";

import classes from "./CoursesSection.module.css";

export default function CoursesSection({ coursesData }: { coursesData: CourseData[] }) {
  return (
    <>
      {coursesData?.map(course => (
        <section key={course.course_id}>
          <div className='container-125'>
            <div className={classes.cardsContainer}>
              <h2 className={classes.sectionHeader}>{course?.course_title}</h2>
              {course?.chapters.map(chapter => (
                <ChapterCard key={chapter.chapter_id} chapterData={chapter} />
              ))}
            </div>
          </div>
        </section>
      ))}
    </>
  );
}
