import FawryIcon from "@/assets/images/fawry.svg?react";
import useUserActionsStore from "@/store/UserActionsStore";
import { FawryResponseBody } from "@/types";
import { SUBSCRIPTION_AMOUNT } from "@/utils/Constants";
import { useTranslation } from "react-i18next";
import LinkBtn from "../LinkBtn/LinkBtn";

import classes from "./FawryDetails.module.css";

export default function FawryDetails({ fawryResponse }: { fawryResponse: FawryResponseBody }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { redirectPath } = useUserActionsStore(state => state);

  return (
    <div>
      <h1 className={classes.header}>{t("pay_with_fawry_label")}</h1>
      <p className={classes.text}>{t("pay_with_fawry_message")}</p>

      <div className={classes.contentWrapper}>
        <div className={classes.topContent}>
          <h2>{t("fawry_reference_number_label")}</h2>
          <p>{fawryResponse?.referenceNumber}</p>
          <h3>{t("fawry_service_code_label")}</h3>
          <p>788</p>
        </div>
        <div className={classes.bottomContent}>
          <FawryIcon />
          <p>
            {t("pay_label")}{" "}
            {language === "en" ? (
              <strong>
                {t("egp_currency_label")} {SUBSCRIPTION_AMOUNT} "+ {t("fawry_fees_label")}"
              </strong>
            ) : (
              <strong>
                {SUBSCRIPTION_AMOUNT} {t("egp_currency_label")} "+ {t("fawry_fees_label")}"
              </strong>
            )}{" "}
            {t("fawry_location_label")}
          </p>
          <p>{t("fawry_location_message_label")}</p>
          <LinkBtn
            variant='signUp'
            to={redirectPath && !redirectPath.includes("payment") ? redirectPath : `/${language}/`}
          >
            {t("finish_label")}
          </LinkBtn>
        </div>
      </div>
    </div>
  );
}
