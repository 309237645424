import useUserActionsStore from "@/store/UserActionsStore";
import useUserStore from "@/store/UserStore";
import { useTranslation } from "react-i18next";

import { Navigate, Outlet } from "react-router-dom";

export default function GuardedRoutes() {
  const { user } = useUserStore(state => state);
  const { redirectPath } = useUserActionsStore(state => state);
  const {
    i18n: { language },
  } = useTranslation();

  if (user && redirectPath) {
    return <Navigate to={redirectPath} />;
  } else if (user) {
    return <Navigate to={`/${language}/`} />;
  }

  return <>{!user && <Outlet />}</>;
}
