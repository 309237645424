import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classes from "./MainFooter.module.css";

export default function MainFooter() {
  const { t } = useTranslation();
  return (
    <footer className={classes.footerContainer}>
      <div className='container-125'>
        <p>
          {t("copyright_label", {
            year: new Date().getFullYear(),
          })}
        </p>
        <div>
          <Link to='terms_conditions/'>{t("terms_conditions_label")}</Link> •{" "}
          <Link to='privacy/'>{t("privacy_policy_label")}</Link>
        </div>
      </div>
    </footer>
  );
}
