import GuardedRoutes from "@/Components/GuardedRoutes/GuardedRoutes";
import { AuthLoader } from "@/Loaders/AuthLoader";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode, Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProtectedRoutes from "./Components/ProtectedRoutes/ProtectedRoutes";
import ErrorPage from "./routes/ErrorPage/ErrorPage";
import AuthPage from "./routes/HomePage/AuthPage/AuthPage";
import ChapterPage from "./routes/HomePage/ChapterPage/ChapterPage";
import HomePage from "./routes/HomePage/HomePage";
import MyAccountPage from "./routes/HomePage/MyAccountPage/MyAccountPage";
import MyDataPage from "./routes/HomePage/MyDataPage/MyDataPage";
import FawryNumberPage from "./routes/HomePage/PaymentPage/FawryNumberPage/FawryNumberPage";
import FawryPaymentDetailsPage from "./routes/HomePage/PaymentPage/FawryNumberPage/FawryPaymentDetailsPage/FawryPaymentDetailsPage";
import PaymentPage from "./routes/HomePage/PaymentPage/PaymentPage";
import PrivacyPage from "./routes/HomePage/PrivacyPage/PrivacyPage";
import AuthWrapper from "./routes/Layout/AuthWrapper/AuthWrapper";
import ChallengeLayout from "./routes/Layout/ChallengeLayout/ChallengeLayout";
import LanguageLayout from "./routes/Layout/LanguageLayout/LanguageLayout";
import MainLayout from "./routes/Layout/MainLayout/MainLayout";
import SecondaryLayout from "./routes/Layout/SecondaryLayout/SecondaryLayout";
import RedirectRoute from "./routes/RedirectRoute";
import { GOOGLE_CLIENT_ID } from "./utils/Constants";

import "@/index.css";
import "./i18n";
import TermsConditionsPage from "./routes/HomePage/TermsConditions/TermsConditionsPage";

const router = createBrowserRouter([
  {
    id: "auth-wrapper",
    element: <AuthWrapper />,
    errorElement: <ErrorPage />,
    loader: AuthLoader,
    shouldRevalidate: () => true,
    children: [
      {
        path: "/",
        element: <RedirectRoute />,
      },
      {
        path: ":lang",
        element: <LanguageLayout />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                index: true,
                element: <HomePage />,
              },
              {
                path: "courses/:course_id/chapters/:chapter_id",
                element: <ChapterPage />,
              },
              {
                path: "courses/:course_id/chapters/:chapter_id/explainer",
                async lazy() {
                  const { ExplainerPage } = await import(
                    "./routes/HomePage/ExplainerPage/ExplainerPage"
                  );
                  return { Component: ExplainerPage };
                },
              },
              {
                path: "privacy",
                element: <PrivacyPage />,
              },
              {
                path: "terms_conditions",
                element: <TermsConditionsPage />,
              },
              {
                path: "my_data",
                element: <MyDataPage />,
              },
              {
                element: <ProtectedRoutes />,
                children: [
                  {
                    path: "myaccount",
                    element: <MyAccountPage />,
                  },
                  {
                    path: "payment",
                    children: [
                      {
                        index: true,
                        element: <PaymentPage />,
                      },
                      {
                        path: "fawry_mobile_number",
                        element: <FawryNumberPage />,
                      },
                      {
                        path: "fawry_payment_details",
                        element: <FawryPaymentDetailsPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "user",
            element: <GuardedRoutes />,
            children: [
              {
                element: <SecondaryLayout />,
                children: [
                  {
                    path: "signIn",
                    element: <AuthPage />,
                  },
                  {
                    path: "signUp",
                    element: <AuthPage />,
                  },
                ],
              },
            ],
          },
          {
            path: "courses/:course_id/chapters/:chapter_id/challenge/:challenge_id",
            element: (
              <Suspense fallback={<></>}>
                <ChallengeLayout />
              </Suspense>
            ),
            children: [
              {
                index: true,
                async lazy() {
                  const { ChallengePage } = await import(
                    "./routes/HomePage/ChallengePage/ChallengePage"
                  );
                  return { Component: ChallengePage };
                },
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </StrictMode>
);
